import {
	PieChart,
	Pie,
	Sector,
	Cell,
	BarChart,
	Bar,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	LabelList,
	ResponsiveContainer,
} from "recharts";
import React, { useEffect, useState } from "react";
import Card from "../../components/layout/Card";
import DatePicker from "../../components/layout/DatePicker";
import PageTitle from "../../components/layout/PageTitle";
import ReportPicker from "../../components/report/ReportPicker";
import moment from "moment";
import Dropdown from "../../components/layout/Dropdown";
import TableLayoutBeta from "../../components/layout/TableLayoutBeta";
import { colours } from "../../styles";
import { difference, startCase, upperCase } from "lodash";
import styled from "styled-components";
import { useMutateData, useQueryData } from "../../hooks";
import gql from "graphql-tag";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import expandIcon from "../../images/streamline-icon-expand.svg";
import { useGetEventsByOrganizationId } from "./queries/eventsApiHooks";
import BulkDownloadButton from "../../components/report/BulkDownloadButton";
import Alert from "react-s-alert";

const COLORS = {
	VIOLATION: "#c96376",
	RESERVED: "#ddcc77",
	OCCUPIED: "#88cced",
	AVAILABLE: "#44b399",
	UNKNOWN: "#0072B2",
};

const BREAKPOINT = "1600px";

const POLLING_INTERVAL_MS = 10000;

function SectionHeader(props) {
	return <h2 style={{ textAlign: "center" }}>{props.children}</h2>;
}

function pluralize(count, singularText, pluralText) {
	return count === 1 ? singularText : pluralText;
}

function formatCount(count, singularText, pluralText) {
	const label = pluralize(count, singularText, pluralText);
	return `${count} ${label}`;
}

function getTextElementWidth(text, textSize = 16) {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	context.font = `${textSize}px ${
		window.getComputedStyle(document.body).fontFamily
	}`;
	return Math.floor(context.measureText(text).width);
}

function sumOccupancy(occupancyRecord) {
	let violation = 0;
	let occupied = 0;
	let reserved = 0;
	let available = 0;
	let totalSpaces = 0;
	let unknown = 0;

	for (const occupancy of occupancyRecord) {
		violation += occupancy.Violation;
		occupied += occupancy.Occupied;
		reserved += occupancy.Reserved;
		available += occupancy.Available;
		totalSpaces += occupancy.TotalSpaces;
		unknown += occupancy.Unknown;
	}

	return {
		Violation: violation,
		Occupied: occupied,
		Reserved: reserved,
		Available: available,
		TotalSpaces: totalSpaces,
		Unknown: unknown,
	};
}

function categoryScaler(occupancyRecord) {
	const sum =
		occupancyRecord.Available +
		occupancyRecord.Occupied +
		occupancyRecord.Reserved +
		occupancyRecord.Violation +
		occupancyRecord.Unknown;
	const scaler = (v) => (sum === 0 ? 20 : (v / sum) * 100);

	return scaler;
}

function breakTextByWidth(text, maxWidth) {
	const words = text.split(/\s+/);
	const spaceWidth = getTextElementWidth("\u00A0");
	const wordsWithLength = words.map((w) => ({
		word: w,
		width: getTextElementWidth(w),
	}));
	const lines = [];

	for (const word of wordsWithLength) {
		const previousLine = lines[lines.length - 1];
		if (
			previousLine &&
			previousLine.width + word.width + spaceWidth * previousLine.words.length <
				maxWidth
		) {
			previousLine.words.push(word.word);
			previousLine.width += word.width;
		} else {
			lines.push({ words: [word.word], width: word.width });
		}
	}

	return lines.map((line) => line.words.join(" "));
}

function SVGTextArea(props) {
	return (
		<text {...props}>
			{props.children.map((line, index) => (
				<tspan key={index} {...props} dy={`${index * props.lineHeight}em`}>
					{line}
				</tspan>
			))}
		</text>
	);
}

function sumTotalOccupancy(siteOccupancy) {
	const total = sumOccupancy(siteOccupancy);
	const scaler = categoryScaler(total);

	const categorySummaries = [];
	let totalSpaces = 0;

	for (const [category, count] of Object.entries(total)) {
		if (category === "TotalSpaces") {
			totalSpaces += count;
		} else {
			categorySummaries.push({
				name: category,
				value: count,
				color: COLORS[upperCase(category)],
				percent: scaler(count).toFixed(1),
			});
		}
	}

	return { totalSpaces, categorySummaries };
}

function TotalOccupancy({ data: siteOccupancy }) {
	const { totalSpaces, categorySummaries: data } =
		sumTotalOccupancy(siteOccupancy);

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const {
			cx,
			cy,
			midAngle,
			innerRadius,
			outerRadius,
			startAngle,
			endAngle,
			fill,
		} = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;

		const rectWidth = 100;
		const rectHeight = 48;
		const rectX = ex + (cos >= 0 ? 1 : -1) * 22 - rectWidth / 2;
		const rectY = ey - (sin >= 0 ? 0 : 1) * rectHeight;

		return (
			<g>
				<text x={cx} y={cy} fontSize={24} fontWeight={600} textAnchor="middle">
					{totalSpaces}
				</text>

				<text x={cx} y={cy} dy={16} textAnchor="middle">
					spaces
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius + 10}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				{rectX > 0 && (
					<>
						<rect
							x={rectX}
							y={rectY}
							width={100}
							height={48}
							rx={4}
							ry={4}
							fill="#44b399"
						/>
						<text
							x={rectX + 52}
							y={rectY + 26}
							textAnchor="middle"
							dominantBaseline="middle"
							fill="white"
							fontSize={24}
						>{`${data.find((d) => d.name === props.name).percent}%`}</text>
					</>
				)}
			</g>
		);
	};

	return (
		<Card style={{ margin: 0, gridArea: "total" }}>
			<SectionHeader>Total Occupancy</SectionHeader>
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ResponsiveContainer width={"90%"} height={384}>
					<PieChart style={{ overflow: "hidden" }}>
						<Pie
							data={data}
							innerRadius={60}
							outerRadius={90}
							dataKey="value"
							activeIndex={data.findIndex((d) => d.name === "Available")}
							activeShape={renderActiveShape}
						>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={data[index].color} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<div
					style={{
						marginTop: "12px",
						display: "flex",
						justifyContent: "center",
						marginBottom: "12px",
						gap: "1rem",
						flexWrap: "wrap",
						width: "300px",
						alignItems: "center",
					}}
				>
					{data.map((item) => (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: "8px",
							}}
							key={item.name}
						>
							<div
								style={{
									width: "10px",
									height: "10px",
									backgroundColor: item.color,
									borderRadius: "10px",
								}}
							/>
							<div
								style={{ wordSpacing: "0.375rem" }}
							>{`${item.name} ${item.percent}%`}</div>
						</div>
					))}
				</div>
			</div>
		</Card>
	);
}

function ChartBarToolTip(props) {
	const { active, payload } = props;

	if (!active || !payload?.length) {
		return null;
	}

	const data = payload[0].payload;
	const rows = [
		{ color: COLORS.VIOLATION, count: data.Violation, label: "Violation" },
		{ color: COLORS.OCCUPIED, count: data.Occupied, label: "Occupied" },
		{ color: COLORS.RESERVED, count: data.Reserved, label: "Reserved" },
		{ color: COLORS.AVAILABLE, count: data.Available, label: "Available" },
		{ color: COLORS.UNKNOWN, count: data.Unknown, label: "Unknown" },
	];

	return (
		<Card>
			<div style={{ fontWeight: 600, fontSize: 16, marginBottom: 10 }}>{`${
				data.TotalSpaces
			} Total ${pluralize(data.TotalSpaces, "Space", "Spaces")}`}</div>
			{rows.map((item) => (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyItems: "center",
						alignItems: "center",
						gap: "1rem",
					}}
					key={item.label}
				>
					<div
						style={{
							width: "10px",
							height: "10px",
							backgroundColor: item.color,
							borderRadius: "10px",
						}}
					/>
					<div>{`${formatCount(item.count, "Space", "Spaces")} ${
						item.label
					}`}</div>
				</div>
			))}
		</Card>
	);
}

function OccupancyBySite({ data }) {
	const SubTotalLabel = (props) => {
		const { x, y, width, height, index } = props; // Bar props

		const space = data[index];

		const categoryName = startCase(props.category);
		const occupancyNumber = space[props.category];

		const minimumPadding = 40;

		const categoryNameAndOccupancyNumber = `${occupancyNumber} ${categoryName}`;

		const spaceCountWillFit =
			getTextElementWidth(occupancyNumber) + minimumPadding < width;
		const fullNameWillFit =
			getTextElementWidth(categoryNameAndOccupancyNumber) + minimumPadding <
			width;

		return (
			<g>
				{spaceCountWillFit && (
					<text
						x={x + width / 2}
						y={y + height / 1.5}
						fill="#fff"
						textAnchor="middle"
						fontSize={16}
						fontWeight={600}
					>
						{fullNameWillFit ? categoryNameAndOccupancyNumber : occupancyNumber}
					</text>
				)}
			</g>
		);
	};

	const getMarginLeft = () => {
		let max = 0;
		for (const space of data) {
			max = Math.max(max, getTextElementWidth(space.Name, 18));
		}

		return Math.floor(max);
	};

	function YAxisTip({ x, y, index }) {
		const space = data[index];

		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={-5}
					textAnchor="end"
					dominantBaseline="top"
					fill="#444"
					fontSize={18}
				>
					{space.Name}
				</text>
				<text x={0} y={15} textAnchor="end" dominantBaseline="top" fill="#444">
					{`${formatCount(space.TotalSpaces, "Space", "Spaces")}`}
				</text>
			</g>
		);
	}

	return (
		<Card
			style={{
				margin: 0,
				display: "flex",
				flexDirection: "column",
				gridArea: "site",
			}}
		>
			<SectionHeader>Occupancy by Site</SectionHeader>

			<ResponsiveContainer minHeight={data.length * 60} minWidth={"90%"}>
				<BarChart
					data={data.map((d) => {
						const scaler = categoryScaler(d);

						return {
							...d,
							violationPercent: scaler(d.Violation),
							occupiedPercent: scaler(d.Occupied),
							reservedPercent: scaler(d.Reserved),
							availablePercent: scaler(d.Available),
							unknownPercent: scaler(d.Unknown),
						};
					})}
					layout="vertical"
					margin={{
						left: getMarginLeft(),
					}}
					overflow="visible"
				>
					<XAxis axisLine={false} type="number" hide />
					<YAxis
						yAxisId={0}
						tick={<YAxisTip />}
						type="category"
						axisLine={false}
						tickLine={false}
					/>
					<Tooltip content={<ChartBarToolTip />} />
					<Bar
						barSize={60}
						dataKey="violationPercent"
						stackId="a"
						fill={COLORS.VIOLATION}
					>
						<LabelList content={<SubTotalLabel category={"Violation"} />} />
					</Bar>
					<Bar dataKey="unknownPercent" stackId="a" fill={COLORS.UNKNOWN}>
						<LabelList content={<SubTotalLabel category={"Unknown"} />} />
					</Bar>
					<Bar dataKey="occupiedPercent" stackId="a" fill={COLORS.OCCUPIED}>
						<LabelList content={<SubTotalLabel category={"Occupied"} />} />
					</Bar>
					<Bar dataKey="reservedPercent" stackId="a" fill={COLORS.RESERVED}>
						<LabelList content={<SubTotalLabel category={"Reserved"} />} />
					</Bar>
					<Bar dataKey="availablePercent" stackId="a" fill={COLORS.AVAILABLE}>
						<LabelList content={<SubTotalLabel category={"Available"} />} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</Card>
	);
}

function OccupancyBySpaceGraph({ data, onExpand, expanded }) {
	const yAxisWidth = expanded ? 260 : 160;

	const spacesRemainingLabel = (props) => {
		const { x, y, width, height, index } = props;

		const space = data[index];

		return (
			<g>
				<text
					x={x + width - 5}
					y={y + height / 2}
					fill="#fff"
					textAnchor="end"
					dominantBaseline="middle"
					fontSize={18}
					fontWeight={600}
				>
					{`${space.Available}/${space.TotalSpaces}`}
				</text>
			</g>
		);
	};

	function XAxisTip({ x, y, index }) {
		const percent = expanded ? index * 10 : index * 50;
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={percent === 100 ? 12 : 0}
					y={0}
					textAnchor="middle"
					fill="#666"
				>
					{percent === 0 || percent === 100 ? `${percent}%` : percent}
				</text>
			</g>
		);
	}

	function YAxisTip({ x, y, index }) {
		const space = data[index];
		const lineHeight = 1.2;
		const spaceNameLines = breakTextByWidth(space.LeaseParkName, yAxisWidth);

		return (
			<g transform={`translate(${x},${y})`}>
				<SVGTextArea
					x={0}
					y={-5}
					textAnchor="end"
					dominantBaseline="top"
					fill="#444"
					width={yAxisWidth}
					lineHeight={lineHeight}
				>
					{spaceNameLines}
				</SVGTextArea>
				<text
					x={0}
					y={-5}
					textAnchor="end"
					dominantBaseline="top"
					fill="#444"
					dy={`${spaceNameLines.length * lineHeight + 0.25}em`}
				>
					{`${formatCount(space.TotalSpaces, "Space", "Spaces")}`}
				</text>
			</g>
		);
	}

	let minimumBarHeight = 50;
	let minimumCategoryGap = 20;
	return (
		<Card
			style={{
				margin: 0,
				gridArea: "space-graph",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<ExpandHeader
				style={{ width: "100%" }}
				title="Occupancy by Space"
				onExpand={onExpand}
			/>

			<ResponsiveContainer
				style={{ marginBottom: "1rem" }}
				height={data.length * (minimumBarHeight + minimumCategoryGap) + 100} // 100 just to be safe
				minWidth={"100%"}
			>
				<BarChart
					style={{ padding: "5px" }}
					data={data.map((d) => {
						const scaler = categoryScaler(d);
						return {
							...d,
							violationPercent: scaler(d.Violation),
							occupiedPercent: scaler(d.Occupied),
							reservedPercent: scaler(d.Reserved),
							availablePercent: scaler(d.Available),
							unknownPercent: scaler(d.Unknown),
						};
					})}
					layout="vertical"
					margin={{
						left: yAxisWidth,
					}}
					overflow="visible"
					barSize={minimumBarHeight}
				>
					<CartesianGrid horizontal={false} strokeDasharray="3 3" />
					<XAxis
						orientation="top"
						tickCount={expanded ? 11 : 3}
						tick={<XAxisTip expanded={expanded} />}
						axisLine={false}
						type="number"
						domain={[0, 100]}
					/>
					<YAxis
						yAxisId={0}
						tick={<YAxisTip />}
						type="category"
						axisLine={false}
						tickLine={false}
					/>
					<Tooltip content={<ChartBarToolTip />} />
					<Bar dataKey="violationPercent" stackId="a" fill={COLORS.VIOLATION} />
					<Bar dataKey="unknownPercent" stackId="a" fill={COLORS.UNKNOWN} />
					<Bar dataKey="occupiedPercent" stackId="a" fill={COLORS.OCCUPIED} />
					<Bar dataKey="reservedPercent" stackId="a" fill={COLORS.RESERVED} />
					<Bar dataKey="availablePercent" stackId="a" fill={COLORS.AVAILABLE}>
						<LabelList content={spacesRemainingLabel} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</Card>
	);
}

function OccupancyBySpaceTable(props) {
	const columns = [
		{
			Header: "Space",
			accessor: "LeaseParkName",
			fixedWidth: 150,
		},
		{
			Header: "Occupied",
			accessor: "Occupied",
		},
		{
			Header: "Reserved",
			accessor: "Reserved",
		},
		{
			Header: "Available",
			accessor: "Available",
		},
		{
			Header: "Total Spaces",
			accessor: "TotalSpaces",
		},
		{
			Header: "Unknown",
			accessor: "Unknown",
		},
		{
			Header: "Violation",
			accessor: "Violation",
			Cell: ({ row: { original } }) =>
				original.Violation > 0 ? (
					<div>
						<div
							style={{
								padding: "4px 8px 4px 8px",
								background: "#fef1f1",
								borderRadius: "4px",
								color: colours.red,
								display: "inline-block",
							}}
						>
							{original.Violation}
						</div>
					</div>
				) : (
					<div style={{ paddingLeft: "8px" }}>0</div>
				),
		},
	];

	return (
		<Card style={{ margin: 0, gridArea: "space-table" }}>
			<ExpandHeader title="Occupancy by Space" onExpand={props.onExpand} />
			<TableLayoutBeta data={props.data} columns={columns} defaultSortBy={[]} />
		</Card>
	);
}

function ExpandHeader(props) {
	const Icon = styled.img`
		height: 32px;

		&:hover {
			cursor: pointer;
		}
	`;

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "start",
				...props.style,
			}}
		>
			<div />
			<h2>{props.title}</h2>
			<Icon src={expandIcon} onClick={props.onExpand} />
		</div>
	);
}

function linearizeSpaceOccupancy(occupancy) {
	if (!occupancy) {
		return [];
	}

	const result = [];
	for (const siteOccupancy of occupancy) {
		for (const spaceOccupancy of siteOccupancy.SpaceOccupancy) {
			result.push(spaceOccupancy);
		}
	}
	return result;
}

function sumSiteOccupancy(occupancy) {
	const result = [];
	for (const siteOccupancy of occupancy) {
		result.push({
			Name: siteOccupancy.Name,
			...sumOccupancy(siteOccupancy.SpaceOccupancy),
		});
	}
	return result;
}

function MetricsContainer({ siteOccupancy }) {
	const defaultGridAreas = `
		"total site site site"
		"space-graph space-graph space-table space-table"
		`;

	const [state, setState] = useState({
		gridTemplateAreas: defaultGridAreas,
		isExpanded: false,
	});

	const toggleExpand = () =>
		setState((_state) => ({
			..._state,
			isExpanded: !_state.isExpanded,
			gridTemplateAreas:
				_state.gridTemplateAreas === defaultGridAreas ? "" : defaultGridAreas,
		}));

	const Wrapper = styled.div`
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@media (min-width: ${BREAKPOINT}) {
			grid-gap: 1rem;
			display: ${state.isExpanded ? "flex" : "grid"};
			grid-template-areas: ${state.isExpanded ? "none" : defaultGridAreas};
			grid-auto-rows: ${state.isExpanded ? "auto" : "min-content"};
		}
	`;

	if (!siteOccupancy?.length) {
		return (
			<Card>
				<div
					style={{
						marginTop: "64px",
						marginBottom: "64px",
						fontSize: 20,
						fontWeight: 600,
						textAlign: "center",
					}}
				>
					No data found
				</div>
			</Card>
		);
	}

	const linearSpaceOccupancy = linearizeSpaceOccupancy(siteOccupancy);
	const siteSumOccupancy = sumSiteOccupancy(siteOccupancy);

	return (
		<Wrapper>
			<TotalOccupancy data={siteSumOccupancy} />
			<OccupancyBySite data={siteSumOccupancy} />
			<OccupancyBySpaceGraph
				data={linearSpaceOccupancy}
				onExpand={toggleExpand}
				expanded={state.isExpanded}
			/>
			<OccupancyBySpaceTable
				data={linearSpaceOccupancy}
				onExpand={toggleExpand}
			/>
		</Wrapper>
	);
}

const ToolBarWrapper = styled.div`
	display: grid;
	background: ${colours.highlightGrey};
	border-radius: 4px;
	padding: 1rem;
	margin-bottom: 1rem;
	grid-gap: 1rem;
	grid-template-areas:
		"sites spaces"
		"event date"
		"download download";

	@media (min-width: ${BREAKPOINT}) {
		grid-template-columns: repeat(6, 1fr);
		grid-template-areas: "sites spaces . event date download";
	}
`;

function useGetOccupancyReport({
	siteIds,
	startDateTime,
	endDateTime,
	eventId,
	leaseParkIds,
}) {
	const {
		data: { getOccupancyReport },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query (
				$siteIds: [Int!]
				$startDateTime: String
				$endDateTime: String
				$eventId: Int
				$leaseParkIds: [Int!]
			) {
				getOccupancyReport(
					siteIds: $siteIds
					startDateTime: $startDateTime
					endDateTime: $endDateTime
					eventId: $eventId
					leaseParkIds: $leaseParkIds
				) {
					IsLive
					SiteOccupancy {
						SiteID
						Name
						SpaceOccupancy {
							LeaseParkID
							LeaseParkName
							TotalSpaces
							Occupied
							Reserved
							Available
							Violation
							Unknown
						}
					}
				}
			}
		`,
		{
			siteIds,
			startDateTime,
			endDateTime,
			eventId,
			leaseParkIds,
		},
		!siteIds.length && !eventId && !leaseParkIds?.length
	);

	return {
		occupancyReport: getOccupancyReport,
		isLoading,
		refetch,
	};
}

function DownloadButton(props) {
	const downloadOccupancy = useMutateData(gql`
		mutation (
			$organizationId: Int!
			$siteIds: [Int!]
			$startDateTime: String
			$endDateTime: String
			$eventId: Int
			$leaseParkIds: [Int!]
		) {
			downloadOccupancyReport(
				organizationId: $organizationId
				siteIds: $siteIds
				startDateTime: $startDateTime
				endDateTime: $endDateTime
				eventId: $eventId
				leaseParkIds: $leaseParkIds
			)
		}
	`);

	return (
		<BulkDownloadButton
			style={{ gridArea: "download" }}
			disabled={!props.siteIds.length && !props.eventId}
			onSubmit={async () => {
				try {
					await downloadOccupancy({
						variables: {
							organizationId: props.organizationId,
							siteIds: props.siteIds,
							startDateTime: props.eventId
								? ""
								: moment(props.date).startOf("day").format(),
							endDateTime: props.eventId
								? ""
								: moment(props.date).endOf("day").format(),
							eventId: props.eventId,
							leaseParkIds: props.leaseParkIds,
						},
					});
					Alert.success(
						"The occupancy report will be sent to your email shortly"
					);
				} catch (error) {
					Alert.error("Something went wrong please try again");
				}
			}}
		/>
	);
}

function filterSpacesForSites(leaseParks, siteIds) {
	if (!leaseParks || !siteIds) {
		return [];
	}
	return leaseParks.filter(({ Site }) => Site && siteIds.includes(Site.SiteID));
}

// add all spaces for sites that were added, remove all spaces for sites that were removed.
function filterSpacesOnSiteChange(
	newSiteIds,
	selectedSiteIds,
	selectedLeaseParkIds,
	leaseParks
) {
	const leaseParkIds = [...selectedLeaseParkIds];
	const addedSites = difference(newSiteIds, selectedSiteIds);
	const removedSites = difference(selectedSiteIds, newSiteIds);

	if (addedSites.length) {
		leaseParkIds.push(
			...filterSpacesForSites(leaseParks, addedSites).map(
				(lp) => lp.LeaseParkID
			)
		);

		return leaseParkIds;
	}

	if (removedSites.length) {
		const spacesToRemove = filterSpacesForSites(leaseParks, removedSites);
		const spaceIdsToRemove = spacesToRemove.map((lp) => lp.LeaseParkID);
		return leaseParkIds.filter((id) => !spaceIdsToRemove.includes(id));
	}

	return selectedLeaseParkIds;
}

export const useGetLeaseParksForTimestamp = (timestamp) => {
	const skip = !timestamp;
	const {
		data,
		loading: isLoadingLeaseParks,
		isError,
		refetch: refetchParks,
	} = useQueryData(
		gql`
			query GetLeaseParksForTimestamp($timestamp: Int!) {
				getLeaseParksByTimestamp(timestamp: $timestamp) {
					AvailableSpaces
					LeaseParkID
					Name
					Spaces
					Site {
						Country
						CreatedOn
						HasLeaseParking
						HasPublicAccess
						HasSessionExpiry
						HasSessionlessAccess
						HasSessionStartStop
						HasT2Integration
						HasUpcomingEvents
						HasValidation
						IsDeleted
						Name
						OrganizationID
						PermitConfigurationIDs
						SiteID
						SiteType
						Timezone
						HasANPR
						HasCasualParking
						Has2FA
						GracePeriodSeconds
					}
				}
			}
		`,
		{ timestamp },
		skip
	);

	const leaseParks = data ? data.getLeaseParksByTimestamp : null;

	return { leaseParks, isLoadingLeaseParks, isError, refetchParks };
};

export default function OccupancyContainer(props) {
	const organizationId = props.selectedOrganization?.OrganizationID;
	const [state, setState] = useState({
		selectedSiteIds: props.availableSites.map((site) => site.SiteID),
		selectedLeaseParkIds: [],
		selectedDate: moment().startOf("day").toDate(),
		searchTokens: "",
		eventOptions: [],
		selectedEventId: null,
		lastAvailableTimestamp: moment().unix(),
	});

	const { leaseParks, isLoadingLeaseParks } = useGetLeaseParksForTimestamp(
		state.lastAvailableTimestamp
	);

	const {
		occupancyReport,
		isLoading: loadingOccupancy,
		refetch: refetchOccupancy,
	} = useGetOccupancyReport({
		siteIds: state.selectedSiteIds,
		startDateTime: moment(state.selectedDate).startOf("day").format(),
		endDateTime: moment(state.selectedDate).endOf("day").format(),
		eventId: state.selectedEventId,
		leaseParkIds: state.selectedLeaseParkIds,
	});

	const {
		data: { getEventsByOrganizationId },
		isLoading: loadingEvents,
	} = useGetEventsByOrganizationId(props.selectedOrganization?.OrganizationID);

	useEffect(() => {
		if (isLoadingLeaseParks) {
			return;
		} else {
			setState((_state) => ({
				..._state,
				selectedLeaseParkIds: leaseParks?.map((lp) => lp.LeaseParkID) ?? [],
			}));
		}
	}, [leaseParks]);

	useEffect(() => {
		const isLive = occupancyReport?.IsLive;
		if (!isLive) {
			return;
		}

		const interval = setInterval(() => {
			refetchOccupancy();
		}, POLLING_INTERVAL_MS);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [occupancyReport?.IsLive]);

	const isLoading = loadingOccupancy || loadingEvents || isLoadingLeaseParks;

	const parkingEvents = getEventsByOrganizationId ?? [];

	const parkingEventOptions = parkingEvents
		.filter((pe) => {
			return pe.Sites.some((site) =>
				state.selectedSiteIds.includes(site.SiteID)
			);
		})
		.map((pe) => ({
			label: pe.Name,
			value: pe.EventID,
			event: pe,
		}));

	const siteOccupancy = occupancyReport?.SiteOccupancy ?? [];

	return (
		<div>
			<PageTitle>Occupancy</PageTitle>
			<ToolBarWrapper>
				<ReportPicker
					name="sites"
					options={props.availableSites.map((site) => ({
						value: site.SiteID,
						label: site.Name,
					}))}
					selected={state.selectedSiteIds}
					onSelectedChanged={(selectedSiteIds) => {
						const leaseParkIds = filterSpacesOnSiteChange(
							selectedSiteIds,
							state.selectedSiteIds,
							state.selectedLeaseParkIds,
							leaseParks
						);

						setState((_state) => ({
							..._state,
							selectedSiteIds,
							selectedLeaseParkIds: leaseParkIds,
						}));
					}}
					wrapperStyle={{ gridArea: "sites", width: "100%" }}
					disabled={Boolean(
						parkingEventOptions.find((o) => o.value === state.selectedEventId)
					)}
				/>

				<ReportPicker
					name="spaces"
					options={
						leaseParks
							? leaseParks
									.filter(({ Site }) => {
										const LeaseParkSiteID = Site?.SiteID;
										const isSelected = state.selectedSiteIds.some((siteId) => {
											return siteId === LeaseParkSiteID;
										});
										return isSelected;
									})
									.map(({ LeaseParkID, Name }) => {
										return {
											value: LeaseParkID,
											label: Name,
										};
									})
							: []
					}
					selected={state.selectedLeaseParkIds}
					onSelectedChanged={(selectedLeaseParkIds) =>
						setState((_state) => ({
							..._state,
							selectedLeaseParkIds,
						}))
					}
					wrapperStyle={{ gridArea: "spaces", width: "100%" }}
				/>

				<Dropdown
					options={parkingEventOptions.filter((parkingEventOption) => {
						const siteIds = parkingEventOption?.event?.Sites.map(
							(site) => site.SiteID
						);
						return state.selectedSiteIds.some((siteId) =>
							siteIds.includes(siteId)
						);
					})}
					value={parkingEventOptions.find(
						(o) => o.value === state.selectedEventId
					)}
					onChange={(selectedEvent) => {
						setState((_state) => {
							const startTimestamp = selectedEvent?.event?.StartTimestamp;
							const endTimestamp = selectedEvent?.event?.EndTimestamp;

							const startDate = selectedEvent
								? moment(startTimestamp, "X").startOf("day").toDate()
								: moment().startOf("day").toDate();

							const endDate = selectedEvent
								? moment(endTimestamp, "X").endOf("day").toDate()
								: null;

							return {
								..._state,
								selectedEventId: selectedEvent?.value,
								selectedDate: selectedEvent ? [startDate, endDate] : startDate,
								lastAvailableTimestamp: startTimestamp,
							};
						});
					}}
					style={{ gridArea: "event" }}
					styles={{
						control: (baseStyles) => ({
							...baseStyles,
							border: "none",
						}),
					}}
					placeholder="Select an event..."
					isSearchable
					isClearable
				/>

				<DatePicker
					style={{ gridArea: "date", width: "100%" }}
					options={{
						clickOpens: Boolean(!state.selectedEventId),
						pickerMode: state.selectedEventId
							? "dateRangePicker"
							: "datePicker",
					}}
					value={state.selectedDate}
					onChange={(day) => {
						const date = day[0];
						const unixTimestamp = moment(date).unix();
						setState((_state) => ({
							..._state,
							selectedDate: date,
							lastAvailableTimestamp: unixTimestamp,
						}));
					}}
				/>

				<DownloadButton
					organizationId={organizationId}
					siteIds={state.selectedSiteIds}
					date={state.selectedDate}
					eventId={state.selectedEventId}
					leaseParkIds={state.selectedLeaseParkIds}
				/>
			</ToolBarWrapper>

			{isLoading ? (
				<LoadingPlaceholder noCardWrapper />
			) : (
				<MetricsContainer siteOccupancy={siteOccupancy} />
			)}
		</div>
	);
}
