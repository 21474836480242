import * as billingApi from "../../../api/billing";
import * as sessionApi from "../../../api/session";
import Alert from "react-s-alert";

export async function submitRefundPayment(
	props,
	values,
	setSubmitting,
	mutations
) {
	const data = {
		amount: values.amount,
		reason: values.reason,
	};

	try {
		if (
			["ParkingSession", "MooringSession"].includes(values.transaction.ItemType)
		) {
			await sessionApi.createRefundForParkingSession(
				values.transaction.SiteID,
				values.transaction.ItemID,
				data
			);
		} else if (values.transaction.ItemType === "UserLeaseCancellation") {
			await billingApi.createRefundForUserLease(
				values.transaction.OrganizationID,
				values.transaction.ItemID,
				data
			);
		} else if (values.transaction.ItemType === "LeasePayment") {
			await billingApi.createRefundForLeasePayment(
				values.transaction.OrganizationID,
				values.transaction.UserLeaseID,
				values.transaction.ItemID,
				data
			);
		} else if (values.transaction.ItemType === "ProductPurchase") {
			await mutations.refundProductPurchase({
				variables: {
					productPurchaseId: parseInt(values.transaction.ItemID),
					amount: parseFloat(data.amount),
					reason: data.reason,
				},
			});
		} else if (values.transaction.ItemType === "MeterParkingSession") {
			await mutations.refundExternalParkingSession({
				variables: {
					externalParkingSessionId: parseInt(values.transaction.ItemID),
					amount: parseFloat(data.amount),
					reason: data.reason,
				},
			});
		}

		setSubmitting(false);
		Alert.success("Payment refunded");
		props.close(true);
	} catch (error) {
		const serverError =
			error.graphQLErrors?.[0]?.extensions?.exception ||
			error.graphQLErrors?.[0]?.extensions;
		const errorCode = serverError?.code;
		const defaultMessage = "Something went wrong. Please try again.";

		switch (errorCode) {
			case "CannotRefundDeletedUser":
				Alert.error("You cannot refund a transaction for a deleted user.");
				break;
			case "TransactionNotCompleted":
				Alert.error(
					"This transaction cannot be refunded as it is not completed yet."
				);
				break;
			case "RefundAmountOutOfRange":
				Alert.error("The refunded amount was outside of the valid range.");
				break;
			case "NotFound": {
				const param = serverError.parameters?.object;
				if (param === "DpsTxnRef") {
					Alert.error(
						"This transaction cannot be refunded as its missing a DPS reference."
					);
				} else if (param === "PxPostBillingDetails") {
					Alert.error(
						"This transaction cannot be refunded as the site is missing PxPost billing details"
					);
				} else {
					Alert.error(defaultMessage);
				}
				break;
			}
			default:
				Alert.error(defaultMessage);
		}

		setSubmitting(false);

		props.close(true);
	}
}

export async function submitResendReceipt(
	props,
	values,
	setSubmitting,
	mutations
) {
	try {
		switch (values.transaction.ItemType) {
			case "ParkingSession":
			case "MooringSession":
				await billingApi.resendParkingSessionReceipt(
					values.transaction.SiteID,
					values.transaction.ItemID
				);
				break;
			case "LeasePayment":
				await billingApi.resendLeasePaymentReceipt(
					values.transaction.OrganizationID,
					values.transaction.UserLeaseID,
					values.transaction.ItemID
				);
				break;
			case "UserLeaseCancellation":
				await billingApi.resendUserLeaseCancellationReceipt(
					values.transaction.OrganizationID,
					values.transaction.ItemID
				);
				break;
			case "ParkingSessionRefund":
			case "MooringSessionRefund":
				await billingApi.resendParkingSessionRefundReceipt(
					values.transaction.SiteID,
					values.transaction.RefundRequestID
				);
				break;
			case "LeasePaymentRefund":
				await billingApi.resendLeasePaymentRefundReceipt(
					values.transaction.OrganizationID,
					values.transaction.RefundRequestID
				);
				break;
			case "UserLeaseCancellationRefund":
				await billingApi.resendUserLeaseCancellationRefundReceipt(
					values.transaction.OrganizationID,
					values.transaction.RefundRequestID
				);
				break;
			case "ProductPurchase":
				await mutations.resendProductPurchaseReceipt({
					variables: { productPurchaseId: parseInt(values.transaction.ItemID) },
				});
				break;
			case "ProductPurchaseRefund":
				await mutations.resendProductPurchaseRefundReceipt({
					variables: { refundRequestId: values.transaction.RefundRequestID },
				});
				break;
		}

		setSubmitting(false);
		Alert.success("The receipt was resent");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateExternalParkingSessionUser(
	props,
	values,
	setSubmitting,
	mutations
) {
	try {
		switch (values.transaction.ItemType) {
			case "MeterParkingSession":
				await mutations.updateExternalParkingSessionUser({
					variables: {
						externalId: parseInt(values.transaction.ItemID),
						email: values.email,
					},
				});
				break;
			default:
				break;
		}

		setSubmitting(false);
		Alert.success("The User was updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function sendExternalParkingSessionReceipt(
	props,
	values,
	setSubmitting,
	mutations
) {
	try {
		switch (values.transaction.ItemType) {
			case "MeterParkingSession":
				await mutations.sendExternalParkingSessionReceipt({
					variables: {
						externalId: parseInt(values.transaction.ItemID),
					},
				});
				break;
			default:
				break;
		}

		setSubmitting(false);
		Alert.success("The Meter Parking Session receipt was sent");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function submitRetryPayment(props, values, setSubmitting) {
	try {
		switch (values.transaction.ItemType) {
			case "ParkingSession":
			case "MooringSession":
				await billingApi.retryParkingSessionPayment(
					values.transaction.SiteID,
					values.transaction.ItemID
				);
				break;
			case "LeasePayment":
				await billingApi.retryLeasePayment(
					values.transaction.SiteID,
					values.transaction.UserLeaseID,
					values.transaction.ItemID
				);
				break;
			case "UserLeaseCancellation":
				await billingApi.retryUserLeaseCancellationFee(
					values.transaction.SiteID,
					values.transaction.UserLeaseID
				);
				break;
		}

		setSubmitting(false);
		Alert.success("Retry payment succeeded");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Retry payment failed.");
		props.close(true);
	}
}

export async function submitVoidPayment(props, values, setSubmitting) {
	try {
		switch (values.transaction.ItemType) {
			case "ParkingSession":
			case "MooringSession":
				await billingApi.voidParkingSessionPayment(
					values.transaction.SiteID,
					values.transaction.ItemID
				);
				break;
			case "LeasePayment":
				await billingApi.voidLeasePayment(
					values.transaction.SiteID,
					values.transaction.UserLeaseID,
					values.transaction.ItemID
				);
				break;
			case "UserLeaseCancellation":
				await billingApi.voidUserLeaseCancellationFee(
					values.transaction.SiteID,
					values.transaction.UserLeaseID
				);
				break;
		}

		setSubmitting(false);
		Alert.success("Marking payment succeeded");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Marking payment failed.");
		props.close(true);
	}
}
