import { colours } from "../styles";
import imageDark from "../images/inugo-dark.png";
import imageLight from "../images/inugo-light.png";
import imperiumImage from "../images/imperium.jpg";
import universalImage from "../images/universal.png";
import careparkImage from "../images/carepark.jpg";
import secureDark from "../images/secure-dark.png";
import secureLight from "../images/secure-light.png";
import sippiDark from "../images/sippi-dark.png";
import sippiLight from "../images/sippi-light.png";
import { startCase } from "lodash";
import { TERNARY_CONDITION_OPTIONS } from "../components/wizards/request-wizard/constants";

export const VALIDATION_TYPES = {
	FIXED: "Fixed",
	PERCENTAGE: "Percentage",
	RATE: "Rate",
	DURATIONDAYS: "DurationDays",
	DURATIONHOURS: "DurationHours",
};

export const PROMOTION_TYPES = {
	FIXED: "Fixed",
	PERCENTAGE: "Percentage",
	RATE: "Rate",
	DURATIONDAYS: "DurationDays",
	DURATIONHOURS: "DurationHours",
};

export const DURATION_TYPES = {
	MONTHS: "Months",
	DAYS: "Days",
	HOURS: "Hours",
	EVENT: "Event",
};

export const PAYMENT_PROCESSING_FIXED_FEE = {
	Stripe: 0.3,
	PaymentExpress: { NZ: 0.1, US: 0.07 },
};

export const PAYMENT_PROCESSING_PERCENTAGE_FEE = {
	Stripe: 2.9,
	PaymentExpress: { NZ: 2.5, US: 3.5 },
};

export const DEFAULT_CONVENIENCE_FEE = {
	Stripe: [
		{ min: 0.01, max: 5, fee: 0.3 },
		{ min: 5.01, max: 20, fee: 0.35 },
		{ min: 20.01, max: 50, fee: 0.65 },
		{ min: 50.01, max: 100, fee: 1 },
		{ min: 100.01, max: 200, fee: 3 },
		{ min: 200.01, max: null, fee: 5 },
	],
	PaymentExpress: [
		{ min: 0.01, max: 5, fee: 0.2 },
		{ min: 5.01, max: 20, fee: 0.35 },
		{ min: 20.01, max: 50, fee: 0.65 },
		{ min: 50.01, max: 100, fee: 1 },
		{ min: 100.01, max: 200, fee: 3 },
		{ min: 200.01, max: null, fee: 5 },
	],
};

export const HARDWARE_STATUSES = {
	Development: "Development",
	Inventory: "Inventory",
	AllocatedForInstall: "Allocated for install",
	Installed: "Installed",
	Uninstalled: "Uninstalled",
	Unknown: "Unknown",
};

export const ACCESS_TYPES = {
	Entry: "Entry",
	Exit: "Exit",
	EntryAndExit: "Entry/Exit",
};

export const ACCESS_TYPE_VALUES = {
	Entry: "Entry",
	Exit: "Exit",
	EntryAndExit: "EntryAndExit",
};

export const PARCS_MANUFACTURER = {
	Amano: "Amano",
	DataPark: "DataPark",
	FlashParking: "FlashParking",
	Magnetic: "Magnetic",
	ScheidtBachmann: "Scheidt & Bachmann",
	SKIDATA: "SKIDATA",
	TIBA: "TIBA",
	WPS: "WPS",
	Other: "Other",
};

export const NODE_POSITIONS = {
	ONE: "ONE",
	TWO: "TWO",
};

export const NODE_POSITION_VALUES = {
	ONE: 1,
	TWO: 2,
};

export const LANE_STATUSES = {
	INSTALLED: "Installed",
	DID_NOT_INSTALL: "DidNotInstall",
};

export const NODE_STATUSES = {
	DEVELOPMENT: "Development",
	INVENTORY: "Inventory",
	ALLOCATED_FOR_INSTALL: "AllocatedForInstall",
	INSTALLED: "Installed",
	UNINSTALLED: "Uninstalled",
	UNKNOWN: "Unknown",
};

export const MOCK = {
	INUGO: "INUGO",
};

export const BEACON_MANUFACTURERS = {
	KONTAKT: "Kontakt",
	INUGO: "Inugo",
};

export const TX_POWER = {
	TO_VALUE: {
		0: -20,
		1: -16,
		2: -12,
		3: -8,
		4: -4,
		5: 0,
		6: 4,
		7: 8,
	},
	get TO_DISPLAY() {
		return Object.keys(TX_POWER.TO_VALUE).reduce(
			(total, currentValue, currentIndex) => {
				total[TX_POWER.TO_VALUE[currentIndex]] = currentValue;
				return total;
			},
			{}
		);
	},
};

export const CLIENTS = {
	INUGO: "IN",
	SECURE: "SE",
};

export const CLIENT_UI_CONFIG = {
	IN: {
		image: { dark: imageDark, light: imageLight },
		colours: { background: colours.darkerBlue, button: "green" },
		logo: { size: { large: "80px", small: "60px" }, padding: "8px" },
	},
	SE: {
		image: { dark: secureDark, light: secureLight },
		colours: { background: colours.secureBlue, button: "secureBlue" },
		logo: { size: { large: "50px", small: "30px" }, padding: "40px" },
	},
	IQ: {
		image: { dark: imperiumImage, light: imperiumImage },
		colours: { background: colours.white, button: "black" },
		logo: { size: { large: "80px", small: "60px" }, padding: "8px" },
	},
	SI: {
		image: { dark: sippiDark, light: sippiLight },
		colours: {
			background: colours.sippiPurple,
			backgroundSecondary: colours.sippiRed,
			button: "sippiPurple",
		},
		logo: { size: { large: "80px", small: "60px" }, padding: "8px" },
	},
	UP: {
		image: { dark: universalImage, light: universalImage },
		colours: { background: colours.white, button: "black" },
		logo: { size: { large: "150px", small: "60px" }, padding: "8px" },
	},
	CA: {
		image: { dark: careparkImage, light: careparkImage },
		colours: { background: colours.white, button: "careparkYellow" },
		logo: { size: { large: "100px", small: "60px" }, padding: "8px" },
	},
};

export const IMPORT_TYPES = {
	WIZARD: "Wizard",
	IMPORT: "Import",
};

export const IMPORT_TYPES_TRANSLATIONS = {
	Wizard: "Wizard",
	Import: "Import via CSV",
};

export const INTEGRATION_TYPES = {
	WEBHOOK: "Webhook",
	POLLING: "Polling",
	FILE_TRANSFER: "FileTransfer",
};

export const INTEGRATION_TYPES_TRANSLATIONS = {
	Webhook: "Webhook",
	Polling: "Polling",
	FileTransfer: "File Transfer",
};

export const EVENT_TYPES = {
	LPR: "LPR",
	LPR_EXIT: "LPRExit",
	LPR_WHITELISTED_PLATE: "LPRWhitelistedPlate",
	LPR_NON_WHITELISTED_PLATE: "LPRNonWhitelistedPlate",
	GATE_OPENED_VIA_CAMERA_EVENT: "GateOpenedViaCameraEvent",
	GATE_REMAINED_CLOSED_VIA_CAMERA_EVENT: "GateRemainedClosedViaCameraEvent",
	START_PARKING_SESSION: "StartParkingSession",
	UPDATE_PARKING_SESSION: "UpdateParkingSession",
	UPDATE_PARKING_SESSION_PLATE: "UpdateParkingSessionPlate",
	UPDATE_PARKING_SESSION_EXPIRY: "UpdateParkingSessionExpiry",
	END_PARKING_SESSION: "EndParkingSession",
	INVITE_USERS_BY_CSV: "InviteUsersByCSV",
	EXPIRE_PARKING_SESSION: "ExpireParkingSession",
	PAID_PARKING_SESSION: "PaidParkingSession",
	REFUND_PARKING_SESSION: "RefundParkingSession",
	CREATE_BOOKING: "CreateBooking",
	CANCEL_BOOKING: "CancelBooking",
	UPDATE_BOOKING: "UpdateBooking",
	PAID_BOOKING: "PaidBooking",
	START_WEB_PAY_SESSION: "StartWebPaySession",
	CREATE_PERMIT: "CreatePermit",
	UPDATE_PERMIT: "UpdatePermit",
	CANCEL_PERMIT: "CancelPermit",
	CREATE_PERMIT_VEHICLE: "CreatePermitVehicle",
	DELETE_PERMIT_VEHICLE: "DeletePermitVehicle",
	CANCEL_PERMIT_VEHICLE_REQUEST: "CancelPermitVehicleRequest",
	CREATE_ENFORCEMENT_VIOLATION: "CreateEnforcementViolation",
	EVENT_STARTS_WITH_MULTI_ACCESS_USER_GROUPS:
		"EventStartsWithMultiAccessUserGroups",
	EVENT_STARTS_WITH_ENFORCEMENT_EXEMPT_USER_GROUPS:
		"EventStartsWithEnforcementExemptUserGroups",
	EVENT_STARTS_WITH_MANUAL_PLATES: "EventStartsWithManualPlates",
	CREATE_SITE_PLATE: "CreateSitePlate",
	DELETE_SITE_PLATE: "DeleteSitePlate",
	PLATE_LINKED_TO_EVENT_PASS: "PlateLinkedToEventPass",
	PLATE_UNLINKED_FROM_EVENT_PASS: "PlateUnlinkedFromEventPass",
};

export const EVENT_TYPES_TRANSLATIONS = {
	LPR: "A camera event is received",
	LPRExit: "An exit camera event is received",
	LPRWhitelistedPlate: "A whitelisted plate is found on camera event",
	LPRNonWhitelistedPlate: "A whitelisted plate is not found on camera event",
	GateOpenedViaCameraEvent: "A gate opened via camera event",
	GateRemainedClosedViaCameraEvent: "A gate did not open via camera event",
	StartParkingSession: "A parking session starts",
	EndParkingSession: "A parking session ends",
	UpdateParkingSession: "A parking session is updated",
	UpdateParkingSessionPlate: "A parking session plate is updated",
	UpdateParkingSessionExpiry: "A parking session expiry is updated",
	InviteUsersByCSV: "Invite users by CSV file",
	ExpireParkingSession: "A parking session expires",
	PaidParkingSession: "A parking session is paid successfully",
	RefundParkingSession: "A parking session is refunded",
	CreateBooking: "A booking is created",
	CancelBooking: "A booking is cancelled",
	UpdateBooking: "A booking is updated",
	PaidBooking: "A booking is paid successfully",
	StartWebPaySession: "A web pay session starts",
	CreatePermit: "A permit is created",
	UpdatePermit: "A permit is updated",
	CancelPermit: "A permit is cancelled",
	CreatePermitVehicle: "A permit vehicle is created",
	DeletePermitVehicle: "A permit vehicle is deleted",
	CancelPermitVehicleRequest: "A permit vehicle request is cancelled",
	CreateEnforcementViolation: "An enforcement violation is created",
	EventStartsWithMultiAccessUserGroups:
		"An event starts with multi access user groups",
	EventStartsWithEnforcementExemptUserGroups:
		"An event starts with enforcement exempt user groups",
	EventStartsWithManualPlates: "An event starts with manual plates",
	CreateSitePlate: "A site plate is created",
	DeleteSitePlate: "A site plate is deleted",
	PlateLinkedToEventPass: "A plate was linked to an event pass",
	PlateUnlinkedFromEventPass: "A plate was unlinked from an event pass",
};

const toEventTypeOptions = (o) => ({
	value: o,
	label: EVENT_TYPES_TRANSLATIONS[o],
});

export const EVENT_TYPES_BY_INTEGRATION_TYPE = {
	[INTEGRATION_TYPES.WEBHOOK]: [
		{
			label: "Camera",
			options: [
				EVENT_TYPES.LPR,
				EVENT_TYPES.LPR_EXIT,
				EVENT_TYPES.LPR_WHITELISTED_PLATE,
				EVENT_TYPES.LPR_NON_WHITELISTED_PLATE,
				EVENT_TYPES.GATE_OPENED_VIA_CAMERA_EVENT,
				EVENT_TYPES.GATE_REMAINED_CLOSED_VIA_CAMERA_EVENT,
			],
		},
		{
			label: "Session",
			options: [
				EVENT_TYPES.START_PARKING_SESSION,
				EVENT_TYPES.END_PARKING_SESSION,
				EVENT_TYPES.UPDATE_PARKING_SESSION,
				EVENT_TYPES.UPDATE_PARKING_SESSION_PLATE,
				EVENT_TYPES.UPDATE_PARKING_SESSION_EXPIRY,
				EVENT_TYPES.EXPIRE_PARKING_SESSION,
				EVENT_TYPES.PAID_PARKING_SESSION,
				EVENT_TYPES.REFUND_PARKING_SESSION,
			],
		},
		{
			label: "Booking",
			options: [
				EVENT_TYPES.CREATE_BOOKING,
				EVENT_TYPES.CANCEL_BOOKING,
				EVENT_TYPES.UPDATE_BOOKING,
			],
		},
		{
			label: "Permit",
			options: [
				EVENT_TYPES.CREATE_PERMIT,
				EVENT_TYPES.UPDATE_PERMIT,
				EVENT_TYPES.CANCEL_PERMIT,
				EVENT_TYPES.CREATE_PERMIT_VEHICLE,
				EVENT_TYPES.DELETE_PERMIT_VEHICLE,
				EVENT_TYPES.CANCEL_PERMIT_VEHICLE_REQUEST,
			],
		},
		{
			label: "Enforcement Violation",
			options: [EVENT_TYPES.CREATE_ENFORCEMENT_VIOLATION],
		},
		{
			label: "Parking Events",
			options: [
				EVENT_TYPES.EVENT_STARTS_WITH_MULTI_ACCESS_USER_GROUPS,
				EVENT_TYPES.EVENT_STARTS_WITH_ENFORCEMENT_EXEMPT_USER_GROUPS,
				EVENT_TYPES.EVENT_STARTS_WITH_MANUAL_PLATES,
			],
		},
		{
			label: "Numberplates",
			options: [
				EVENT_TYPES.CREATE_SITE_PLATE,
				EVENT_TYPES.DELETE_SITE_PLATE,
				EVENT_TYPES.PLATE_LINKED_TO_EVENT_PASS,
				EVENT_TYPES.PLATE_UNLINKED_FROM_EVENT_PASS,
			],
		},
	].map((group) => ({
		...group,
		options: group.options.map(toEventTypeOptions),
	})),
	[INTEGRATION_TYPES.POLLING]: [
		{
			label: "Camera",
			options: [
				{
					value: EVENT_TYPES.LPR,
					label: "Retrieve and cache camera events",
				},
			],
		},
	],
	[INTEGRATION_TYPES.FILE_TRANSFER]: [
		{
			label: "Users",
			options: [
				{
					value: EVENT_TYPES.INVITE_USERS_BY_CSV,
					label: "Invite users by CSV file",
				},
			],
		},
	],
};

export const API_KEY_SCOPE = {
	GET_PLACES: "GetPlaces",
	GET_RIGHTS_FOR_PLACES: "GetRightsForPlaces",
	POST_OBSERVATION: "PostObservation",
	MILESIGHT_POST_OBSERVATION: "MileSightPostObservation",
	UPDATE_RIGHTS_FOR_PLACES: "UpdateRightsForPlaces",
	GET_GROUPS: "GetGroups",
	GET_SUB_PLACES: "GetSubPlaces",
	GET_EVENTS: "GetEvents",
	GET_EVENT_PASSES: "GetEventPasses",
	GET_OCCUPANCY: "GetOccupancy",
	GET_OBSERVATIONS: "GetObservations",
	GET_SESSIONS: "GetSessions",
	GET_BOOKINGS: "GetBookings",
	GET_BOOKING_PAYMENTS: "GetBookingPayments",
};

export const API_KEY_SCOPE_TRANSLATIONS = {
	GetPlaces: "Get places",
	GetRightsForPlaces: "Get rights for places",
	PostObservation: "POST Observation",
	UpdateRightsForPlaces: "Update rights for places",
	MileSightPostObservation: "POST Observation from MileSight camera",
	GetGroups: "Get access groups",
	GetSubPlaces: "Get sub spaces",
	GetEvents: "Get events",
	GetEventPasses: "Get event passes",
	GetOccupancy: "Get occupancy",
	GetObservations: "Get observations",
	GetSessions: "Get sessions",
	GetBookings: "Get bookings",
	GetBookingPayments: "Get bookings payments",
};

export const API_KEY_SCOPE_OPTIONS = Object.values(API_KEY_SCOPE).map(
	(val) => ({
		value: val,
		label: API_KEY_SCOPE_TRANSLATIONS[val],
	})
);

export const CREDIT_CARD_TYPES = {
	UNKNOWN: "Unknown",
	VISA: "Visa",
	MASTERCARD: "MasterCard",
	AMERICAN_EXPRESS: "American Express",
	DISCOVER: "Discover",
};

export const SITE_TYPES = {
	GATELESS: "Gateless",
	GATED: "Gated",
};

export const WEBHOOK_PARAM_TIMEZONES = {
	SITE: "site",
	UTC: "UTC",
};

export const FILE_TRANSFER_REQUEST_METHODS = {
	GET: "GET",
};

export const COLORS_BY_REQUEST_METHOD = {
	POST: "orange",
	GET: "green",
	PUT: "blue",
	DELETE: "red",
};

export const AUTHORIZATION_TYPES = {
	BASIC: "Basic",
	BEARER: "Bearer",
	CUSTOM: "Custom",
	API_KEY: "APIKey",
};

export const AUTHORIZATION_TYPE_TRANSLATIONS = {
	Basic: "Basic",
	Bearer: "Bearer",
	Custom: "Custom",
	APIKey: "API Key",
};

export const AUTHORIZATION_OPTIONS = [
	{ value: AUTHORIZATION_TYPES.BASIC, label: "Basic Auth" },
	{ value: AUTHORIZATION_TYPES.BEARER, label: "Bearer Token" },
	{ value: AUTHORIZATION_TYPES.CUSTOM, label: "Custom" },
];
export const RESPONSE_METHOD_LABELS = {
	Nothing: "Do Nothing",
	Map: "Map to Variables",
	Cache: "Cache Response",
};
export const REQUEST_HANDLER_METHOD_LABELS = {
	Nothing: "Do Nothing",
	Handle: "Define Handler",
};
export const RULE_OPTIONS_BY_PROP = {
	WHEN: [{ value: "Before", label: "Before Run" }],
	TYPE: [
		{ value: "Variable", label: "Variable" },
		{ value: "DerivedVariable", label: "Derived Variable" },
		{ value: "ResponseCode", label: "Response Code" },
		{ value: "ResponseBody", label: "Response Body" },
		{ value: "True", label: "True" },
	],
	CONDITION: [
		{ value: "NotSet", label: "Not Set" },
		{ value: "Equals", label: "Equals" },
		{ value: "NotEquals", label: "Not Equals" },
		{ value: "IsEmpty", label: "Is Empty" },
		{ value: "IsTruthy", label: "Is Truthy" },
	],
	OUTCOME: [
		{ value: "Request", label: "Run Request" },
		{ value: "UnsetVariable", label: "Unset Variable" },
	],
};

const convertToStringTransformation = {
	value: "ConvertToString",
	label: "Convert to String",
	getDescription: ({ variableName, type, name }) => (
		<>
			Convert <strong>{variableName}</strong> from <strong>{type}</strong> to{" "}
			<strong>String</strong> and store in <strong>{name}</strong>
		</>
	),
};

const getTernaryDescription = (transformation) => {
	const { variableName, name, ternary } = transformation;
	return (
		<>
			Set <strong>{name}</strong> to{" "}
			<strong>{startCase(ternary.ifTrue)}</strong> if{" "}
			<strong>{variableName}</strong>{" "}
			{TERNARY_CONDITION_OPTIONS.find(
				(tco) => tco.value === ternary.condition
			)?.label?.toLowerCase()}
			, otherwise set it to <strong>{startCase(ternary.ifFalse)}</strong>
		</>
	);
};

export const TRANSFORMATIONS = {
	Float: [
		convertToStringTransformation,
		{
			value: "Cents",
			label: "Dollars to Cents",
			getDescription: ({ variableName, name }) => (
				<>
					Convert <strong>{variableName}</strong> from <strong>Dollars</strong>{" "}
					to <strong>Cents</strong> and store in <strong>{name}</strong>
				</>
			),
		},
		{
			value: "ConvertToNegativeFloat",
			label: "Convert to Negative Float",
			getDescription: ({ variableName, name }) => (
				<>
					Convert <strong>{variableName}</strong> from a{" "}
					<strong>positive</strong> floating point value to a{" "}
					<strong>negative</strong> one and store in <strong>{name}</strong>
				</>
			),
		},
		{
			value: "ToFixed",
			label: "Format using fixed-point notation",
			getDescription: ({ variableName, name, digits }) => (
				<>
					Format <strong>{variableName}</strong> using{" "}
					<strong>fixed-point notation</strong> to{" "}
					<strong>
						{digits} digit
						{digits === 1 ? "" : "s"}
					</strong>{" "}
					and store in <strong>{name}</strong>
				</>
			),
		},
	],
	Timestamp: [
		{
			value: "Format",
			label: "Format",
			getDescription: (transformation) => {
				const { variableName, name, template, format } = transformation;
				return (
					<>
						Format <strong>{variableName}</strong> as{" "}
						<strong>{template}</strong> using <strong>{format?.label}</strong>{" "}
						and store in <strong>{name}</strong>
					</>
				);
			},
		},
		{
			value: "AddHours",
			label: "Add Hours",
			getDescription: (transformation) => {
				const { variableName, name, hours } = transformation;
				return (
					<>
						Add{" "}
						<strong>
							{hours} hour{hours === 1 ? "" : "s"}
						</strong>{" "}
						to the <strong>{variableName}</strong> and store in{" "}
						<strong>{name}</strong>
					</>
				);
			},
		},
		{
			value: "Ternary",
			label: "Ternary",
			getDescription: getTernaryDescription,
		},
	],
	Integer: [
		convertToStringTransformation,
		{
			value: "ConvertToNegativeInteger",
			label: "Convert to Negative Integer",
			getDescription: ({ variableName, name }) => (
				<>
					Convert <strong>{variableName}</strong> from a{" "}
					<strong>positive</strong> integer value to a <strong>negative</strong>{" "}
					one and store in <strong>{name}</strong>
				</>
			),
		},
	],
	Action: [
		{
			value: "GenerateRandomString",
			label: "Generate Random String",
			getDescription: ({ characterLength, name }) => (
				<>
					Generate a random string of{" "}
					<strong>
						{characterLength} character{characterLength === 1 ? "" : "s"}
					</strong>{" "}
					and store in <strong>{name}</strong>
				</>
			),
		},
		{
			value: "FromTemplate",
			label: "From Template",
			getDescription: ({ template, name }) => (
				<>
					Generate a string from a template <strong>{template}</strong> and
					store in <strong>{name}</strong>
				</>
			),
		},
		{
			value: "GenerateTimestamp",
			label: "Generate Timestamp",
			getDescription: (transformation) => {
				const isHours = transformation.timestampBuilder?.Add?.Hours
					? true
					: false;
				const add = transformation.timestampBuilder?.Add;
				const value = isHours ? add?.Hours : add?.Days;
				return (
					<>
						Create a timestamp by adding{" "}
						<strong>
							{value} {isHours ? "hour" : "day"}
							{value === 1 ? "" : "s"}
						</strong>{" "}
						to{" "}
						<strong>{startCase(transformation.timestampBuilder?.From)}</strong>{" "}
						and store in <strong>{transformation.name}</strong>
					</>
				);
			},
		},
	],
	String: [
		{
			value: "FindAndReplace",
			label: "Find and Replace",
			getDescription: ({ find, name, replace, variableName }) => (
				<>
					Find and replace <strong>{find}</strong> and replace it with{" "}
					<strong>{replace ? replace : <i>nothing</i>}</strong> in{" "}
					<strong>{variableName}</strong> and store in <strong>{name}</strong>
				</>
			),
		},
		{
			value: "Base64Encode",
			label: "Base 64 Encode",
			getDescription: ({ name, variableName }) => (
				<>
					Base 64 encode <strong>{variableName}</strong> and store in{" "}
					<strong>{name}</strong>
				</>
			),
		},
		{
			value: "SHA256",
			label: "Hash using SHA256",
			getDescription: ({ transformation, variableName, name, key }) => (
				<>
					Hash <strong>{variableName}</strong> using{" "}
					<strong>{transformation?.value}</strong> and key{" "}
					<strong>{key}</strong> and store in <strong>{name}</strong>
				</>
			),
		},
		{
			value: "ToLowerCase",
			label: "Convert to lower case",
			getDescription: ({ name, variableName }) => (
				<>
					Convert <strong>{variableName}</strong> to lower case and store in{" "}
					<strong>{name}</strong>
				</>
			),
		},
		{
			value: "ConvertToNumber",
			label: "Convert to Number",
			getDescription: ({ variableName, type, name }) => (
				<>
					Convert <strong>{variableName}</strong> from <strong>{type}</strong>{" "}
					to <strong>Number</strong> and store in <strong>{name}</strong>
				</>
			),
		},
	],
	Boolean: [
		{
			value: "MapValue",
			label: "Map boolean",
			getDescription: ({ variableName, mapping, name }) => (
				<>
					Map the <strong>{variableName}</strong> boolean value to{" "}
					<strong>{mapping["true"]?.value}</strong> if true and{" "}
					<strong>{mapping["false"]?.value}</strong> if false and store in{" "}
					<strong>{name}</strong>
				</>
			),
		},
		{
			value: "Ternary",
			label: "Ternary",
			getDescription: getTernaryDescription,
		},
	],
};

export const TIMESTAMP_FORMAT_OPTIONS = [
	{ value: "UTC", label: "UTC" },
	{ value: "Timezone", label: "Site Timezone" },
];

export const CURRENCIES = [
	{
		value: "USD",
		label: "United States Dollar (USD)",
	},
	{
		value: "GBP",
		label: "British Pound (GBP)",
	},
	{
		value: "AUD",
		label: "Australian Dollar (AUD)",
	},
	{
		value: "NZD",
		label: "New Zealand Dollar (NZD)",
	},
];

export const TIME_ZONES = [
	{
		value: "America/Los_Angeles",
		label: "United States - Pacific (PT)",
	},
	{
		value: "America/Phoenix",
		label: "United States - Mountain (MT)",
	},
	{
		value: "America/Chicago",
		label: "United States - Central (CT)",
	},
	{
		value: "America/New_York",
		label: "United States - Eastern (ET)",
	},
	{
		value: "Europe/London",
		label: "United Kingdom (GMT)",
	},
	{
		value: "Australia/Perth",
		label: "Australia - Western Australia (AWST)",
	},
	{
		value: "Australia/Darwin",
		label: "Australia - Northern Territory (ACST)",
	},
	{
		value: "Australia/Adelaide",
		label: "Australia - South Australia (ACST)",
	},
	{
		value: "Australia/Brisbane",
		label: "Australia - Queensland (AEST)",
	},
	{
		value: "Australia/Sydney",
		label: "Australia - New South Wales (AEST)",
	},
	{
		value: "Australia/Melbourne",
		label: "Australia - Victoria (AEST)",
	},
	{
		value: "Australia/Hobart",
		label: "Australia - Tasmania (AEST)",
	},
	{
		value: "Pacific/Auckland",
		label: "New Zealand (NZST)",
	},
];

export const TAX_TYPES = [
	{ label: "GST", value: "GST" },
	{ label: "Sales Tax", value: "Sales Tax" },
	{ label: "Tax", value: "Tax" },
	{ label: "VAT", value: "VAT" },
	{ label: "Zero Rated", value: "Zero Rated" },
];

export const GENERIC_INPUT_TYPES = {
	TEXT: "Text",
	SELECT: "Select",
	MULTI_SELECT: "MultiSelect",
	NUMBER: "Number",
	MULTI_CHOICE_BUTTON: "MultiChoiceButton",
};

export const REQUEST_METHODS = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	DELETE: "DELETE",
};

export const CREATE_METHOD_KEYS = {
	CREATE: "New",
	IMPORT: "Import",
	TEMPLATE: "Template",
};

export const CREATE_METHOD_LABELS = {
	CREATE: "Create New",
	IMPORT: "Import from File",
	TEMPLATE: "Create from Template",
};

export const CREATE_METHODS = [
	{ value: CREATE_METHOD_KEYS.CREATE, label: CREATE_METHOD_LABELS.CREATE },
	{ value: CREATE_METHOD_KEYS.TEMPLATE, label: CREATE_METHOD_LABELS.TEMPLATE },
	{ value: CREATE_METHOD_KEYS.IMPORT, label: CREATE_METHOD_LABELS.IMPORT },
];

export const CREATE_METHOD_BY_KEY = {
	[CREATE_METHOD_KEYS.CREATE]: CREATE_METHOD_LABELS.CREATE,
	[CREATE_METHOD_KEYS.IMPORT]: CREATE_METHOD_LABELS.IMPORT,
	[CREATE_METHOD_KEYS.TEMPLATE]: CREATE_METHOD_LABELS.TEMPLATE,
};

export const OFFENCE_EVENTS = {
	NOT_PAID: "NotPaid",
	PERMIT_OVERSTAY: "PermitOverstay",
	PARKING_OVERSTAY: "ParkingOverstay",
	NOT_ALLOWED_ON_LOCATION: "NotAllowedOnLocation",
};

export const OFFENCE_EVENTS_TRANSLATIONS = {
	NotPaid: "Not Paid",
	PermitOverstay: "Permit Overstay",
	ParkingOverstay: "Parking Overstay",
	NotAllowedOnLocation: "Not Allowed on Location",
};

export const PAGINATION_EVENTS = {
	INIT: "INIT",
	NEXT: "NEXT",
	PREVIOUS: "PREVIOUS",
	SORT: "SORT",
	GOTO: "GOTO",
	PAGE_SIZE: "PAGE_SIZE",
	SEARCH: "SEARCH",
};

export const QR_CODE_TYPES = {
	LOCATION: "Location",
	LOCATION_SIGNAGE: "LocationSignage",
	VALIDATION: "Validation",
};

export const VALIDATION_WIZARD_TYPE = {
	BEACON: "Beacon",
	QR_CODE: "QRCode",
	AUTO_VALIDATION: "AutoValidation",
};

export const VALIDATION_WIZARD_TYPE_DISPLAY = {
	Beacon: "Beacon",
	QRCode: "QR Code",
	AutoValidation: "Auto Validation",
};

export const VALIDATION_TYPE_OPTIONS = [
	{ value: VALIDATION_TYPES.FIXED, label: "Fixed Amount" },
	{ value: VALIDATION_TYPES.PERCENTAGE, label: "Percentage Amount" },
	{ value: VALIDATION_TYPES.RATE, label: "Validation Rate" },
	// { value: VALIDATION_TYPES.DURATIONHOURS, label: "Duration Hours" },
	// { value: VALIDATION_TYPES.DURATIONDAYS, label: "Duration Days" },
];

export const defaultTimeFormat = "hh:mm A on DD MMM YYYY";

export const INTEGRATION_CONTEXT = {
	ACCOUNT: "Account",
	OPERATOR: "Operator",
	CLIENT: "Client",
};
