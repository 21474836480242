import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import * as spaceSteps from "./space-steps";
import React, { useState } from "react";
import WizardLayout, { StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../../components/layout/Button";
import MultiChoiceButton from "../../../components/wizards/MultiChoiceButton";
import WizardNavigation from "../../../components/wizards/WizardNavigation";
import moment from "moment";
import { rebuildGroups } from "../booking-rate-wizard/helper";
import { trim } from "lodash";

const constructWizard = (props) => {
	if (props.mode === "remove") {
		return {
			initialStep: 0,
			steps: [spaceSteps.removeStep],
		};
	} else {
		let plan = { initialStep: 0 };

		if (props.spaceType === "Private") {
			//Add the Minimum+Maximum duration step if the  space is corporate-type
			plan.steps = [
				spaceSteps.nameStep,
				spaceSteps.sitesStep,
				spaceSteps.spacesStep,
				spaceSteps.occupancyPriorityStep,
				spaceSteps.groups,
				spaceSteps.availabilityStep,
				spaceSteps.billingStep,
				spaceSteps.summaryStep,
			];
		} else {
			plan.steps = [
				spaceSteps.nameStep,
				spaceSteps.sitesStep,
				spaceSteps.spacesStep,
				spaceSteps.occupancyPriorityStep,
				spaceSteps.availabilityStep,
				spaceSteps.billingStep,
				spaceSteps.summaryStep,
			];
		}

		if (props.mode === "update") {
			plan.initialStep = plan.steps.length - 1;
		}

		return plan;
	}
};

function _restoreSites(leasePark) {
	if (!leasePark.sites || leasePark.sites.length === 0) {
		return [];
	}

	return leasePark.sites.map((site) => ({
		value: site.SiteID,
		label: site.Name,
	}));
}

/**
 *
 */
export default function SpaceWizard(props) {
	let spaceType = props.leasePark.SpaceType ? props.leasePark.SpaceType : null;
	const [state, setState] = useState({ spaceType: spaceType });
	if (state.spaceType) {
		spaceType = state.spaceType;
	}

	/*
	If creating a new Space, make sure to get the Space's details before continuing
	*/
	if (props.mode === "add" && !state.spaceType) {
		return (
			<WizardLayout
				key={1} //prevent react render reusing the wrong dom node
				close={props.close}
				title={"Create space"}
				values={[{ name: "spaceType", value: "Public" }]}
				onSubmit={(values, { setSubmitting }) => {
					if (values.spaceType === "Public") {
						setState((_state) => ({ ..._state, spaceType: "Public" }));
					} else if (values.spaceType === "Private") {
						setState((_state) => ({ ..._state, spaceType: "Private" }));
					}
					setSubmitting(false);
				}}
				steps={[
					({ values, setFieldValue, handleSubmit }) => {
						return {
							id: "chooseLeasespaceType",
							label: "Park Type",
							render: () => {
								return (
									<div>
										<StepTitle>
											What type of spaces do you want to add?
										</StepTitle>

										<div>
											Public spaces are generally available for the public to
											book themselves through an app. Private spaces are
											generally allocated to a group of individuals and are
											managed by the corporate administrator.
										</div>

										<MultiChoiceButton
											selected={values.spaceType === "Public"}
											onClick={() => {
												setFieldValue("spaceType", "Public");
											}}
										>
											Public
										</MultiChoiceButton>

										<MultiChoiceButton
											selected={values.spaceType === "Private"}
											onClick={() => {
												setFieldValue("spaceType", "Private");
											}}
										>
											Private
										</MultiChoiceButton>
									</div>
								);
							},
							footer: () => {
								return (
									<WizardNavigation
										rightItems={[
											<Button key="submit" color="blue" onClick={handleSubmit}>
												Next
											</Button>,
										]}
									/>
								);
							},
						};
					},
				]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	const wizard = constructWizard({ ...props, spaceType: spaceType });
	let leasePark = props.leasePark;

	/**
	 * Gets the Value array for the Formik form to store.
	 * Since this varies depending on the park type,
	 * this is a function instead.
	 * @private
	 */
	function _getValueRulesForspaceType(_spaceType) {
		let valueArray = [
			{ name: "spaceType", value: _spaceType },
			{ name: "mode", value: props.mode, validator: Yup.string() },
			{
				name: "name",
				value: leasePark.Name ? leasePark.Name : "",
				validator: Yup.string()
					.max(40, "Name must be 40 characters or shorter")
					.test("isReadable", "Name is a required field", (val) => {
						return trim(val) !== "";
					}),
			},
			{
				name: "sites",
				value: _restoreSites(leasePark),
				validator: Yup.array(Yup.object()),
			},
			{
				name: "spaces",
				value: leasePark.Spaces ? leasePark.Spaces : "",
				validator: Yup.number()
					.integer("Please add at least 1 space")
					.min(1, "Please add at least 1 space")
					.test(
						"mustSupportAllocatedSpaces",
						() => {
							//Hacklike behavior to force showing the allocated spaces in the error message which is otherwise impossible
							const lowerLimit = props.leasePark.spacesInUse;
							return `${lowerLimit} monthly spaces have been booked; you can only adjust the number of spaces to greater or equal to ${lowerLimit}.`;
						},
						(val) => {
							//If the number of active monthly spaces is defined, validate against this
							if (!leasePark.spacesInUse) {
								return true;
							}

							const lowerLimit = leasePark.spacesInUse;

							return !(val < lowerLimit);
						}
					),
			},
			{
				name: "startDate",
				value: leasePark.StartDate
					? moment(leasePark.StartDate, "YYYYMMDD").toDate()
					: null,
			},
			{
				name: "endDate",
				value: leasePark.EndDate
					? moment(leasePark.EndDate, "YYYYMMDD").toDate()
					: null,
			},
			{
				name: "editFromSummary",
				value: props.mode === "update",
				validator: Yup.boolean(),
			},
			{
				name: "leaseParkId",
				value: leasePark.LeaseParkID,
				validator: Yup.number().integer(),
			},
			{
				name: "organizationAccessGroups",
				value: rebuildGroups(
					leasePark.organizationAccessGroups,
					_spaceType === "Private"
				),
				validator: Yup.array(Yup.object()).nullable(),
			},
			{
				name: "billingDetailsId",
				value: leasePark.BillingDetailsID || null,
				validator: Yup.number().integer().nullable(),
			},
			{
				name: "occupancyPriority",
				value: leasePark.OccupancyPriority,
				validator: Yup.number().integer().nullable(),
			},
		];

		return valueArray;
	}

	function clearSpaceType() {
		setState((_state) => ({ ..._state, spaceType: null }));
	}

	return (
		<WizardLayout
			key={2} //prevent react render reusing the wrong dom node
			close={props.close}
			title={(values) => {
				if (values.mode === "add") return "Create space";
				if (values.mode === "update") return "Update space";
				if (values.mode === "remove") return "Remove space";
			}}
			values={_getValueRulesForspaceType(spaceType)}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				const organizationId = props.organization.OrganizationID;
				const selectedSites = values.sites;
				// These are the groups selected in the wizard in format {label, value}
				const selectedGroups = values.organizationAccessGroups;
				// These are the groups with all fields in format {OrganizationAccessGroupID, Name, Sites}
				const fullGroupData = props.organizationAccessGroups;
				const fullSelectedGroups = fullGroupData.filter((group) =>
					selectedGroups.some(
						(selectedGroup) =>
							selectedGroup.value === group.OrganizationAccessGroupID
					)
				);
				const leaseParkId = values?.leaseParkId;

				for (const group of fullSelectedGroups) {
					// If a group's attached sites contain any of the selectedSites
					// and the LeaseParkID is not the current leaseParkId
					// then it is not allowed, it's already mapped to a space for this site.
					for (const groupSite of group.Sites) {
						for (const selectedSite of selectedSites) {
							if (
								selectedSite.value === groupSite.SiteID &&
								groupSite.LeaseParks?.some(
									(_leasePark) => _leasePark.LeaseParkID !== leaseParkId
								)
							) {
								const modeLabel = values.mode === "add" ? "create" : "update";
								Alert.error(
									`Unable to ${modeLabel} space block. Group ${group.Name} already belongs to a space block on this site.`
								);
								setSubmitting(false);
								return;
							}
						}
					}
				}

				if (values.mode !== "remove") {
					const data = {
						name: values.name,
						spaces: values.spaces,
						siteIds:
							values && values.sites
								? values.sites.map((item) => item.value)
								: [],
						startDate: values.startDate
							? moment(values.startDate).format("YYYYMMDD")
							: null,
						endDate: values.endDate
							? moment(values.endDate).format("YYYYMMDD")
							: null,
						organizationAccessGroupIds: [],
						billingDetailsId: values.billingDetailsId,
						occupancyPriority: values.occupancyPriority,
					};

					//Also get min/max durations, groups if a Private space
					if (spaceType === "Private") {
						data.organizationAccessGroupIds =
							values.organizationAccessGroups.reduce((arr, item) => {
								if (item.value === -1) {
									return arr;
								}
								arr.push(item.value);
								return arr;
							}, []);
					}

					if (values.mode === "add") {
						data.spaceType = spaceType;

						try {
							await installationApi.createLeasePark(organizationId, data);

							Alert.success("Space created");
							props.close(true);
						} catch (error) {
							Alert.error("Something went wrong. Please try again.");
						}
					} else if (values.mode === "update") {
						try {
							await installationApi.updateLeasePark(
								organizationId,
								props.leasePark.LeaseParkID,
								data
							);

							Alert.success("Space updated");
							props.close(true);
						} catch (error) {
							Alert.error("Something went wrong. Please try again.");
						}
					}
				} else if (values.mode === "remove") {
					try {
						await installationApi.deleteLeasePark(
							organizationId,
							props.leasePark.LeaseParkID
						);

						Alert.success("Space removed");
						props.close(true);
					} catch (error) {
						if (error.errors && error.errors[0]) {
							const code = error.errors[0].code;
							switch (code) {
								case "SpaceInUse":
									Alert.error("This space has bookings associated with it.");
									break;
								default:
									Alert.error("Something went wrong. Please try again.");
									break;
							}
						} else {
							Alert.error("Something went wrong. Please try again.");
						}
					}
					setSubmitting(false);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={{
				...props,
				spaceType: spaceType,
				clearSpaceType: clearSpaceType,
			}}
		/>
	);
}
