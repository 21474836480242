import React, { useState } from "react";
import { usePermissions, useQueryData, useSearchFilter } from "../../../hooks";
import AddKioskWizard from "../../wizards/kiosk-wizard/AddKioskWizard";
import Button from "../../layout/Button";
import { Plus } from "react-feather";
import ReportWrapper from "../ReportWrapper";
import gql from "graphql-tag";
import { booleanValueFormatter } from "../../layout/TableLayout";

const defaultSorting = [
	{
		id: "SerialNumber",
		desc: false,
	},
];

export default function Kiosks(props) {
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		wizardOpen: false,
	});

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = () => {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
		}));
	};

	const columns = [
		{
			id: "SerialNumber",
			Header: "Serial Number",
			accessor: (d) => d.SerialNumber || "",
			highlightCell: (cellProps) => cellProps.original.SerialNumber || "",
		},
		{
			id: "Manufacturer",
			Header: "Manufacturer",
			accessor: (d) => d.Manufacturer || "",
			highlightCell: (cellProps) =>
				cellProps.original.HardwareManufacturer || "",
		},
		{
			id: "ManufacturerID",
			Header: "Manufacturer ID",
			accessor: (d) => d.ManufacturerID || "",
			highlightCell: (cellProps) => cellProps.original.ManufacturerID || "",
		},
		{
			id: "Connectivity",
			Header: "Connectivity",
			accessor: (d) => d.Connectivity || "",
			highlightCell: (cellProps) => cellProps.original.Connectivity || "",
		},
		{
			id: "SoftwareVersion",
			Header: "Software Version",
			accessor: (d) => d.SoftwareVersion || "",
			highlightCell: (cellProps) => cellProps.original.SoftwareVersion || "",
		},
		{
			id: "Site",
			Header: "Site",
			accessor: (d) => d.Site?.Name || "",
			highlightCell: (cellProps) => cellProps.original.Site?.Name || "",
		},

		{
			id: "SiteType",
			Header: "Site Type",
			accessor: (d) => d.Site?.SiteType || "",
			Cell: (cellProps) => {
				if (cellProps.original.Site?.SiteType === "Gateless") {
					return "Ungated";
				}
				return cellProps.original.Site?.SiteType || "";
			},
		},
		{
			id: "Client",
			Header: "Client",
			accessor: (d) => d.Client?.Name || "",
			highlightCell: (cellProps) => cellProps.original.Client?.Name || "",
		},
		{
			id: "IsVirtualMeter",
			Header: "Virtual Meter",
			accessor: (d) => d.IsVirtualMeter,
			Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		},
	];

	const {
		data: { getAllKiosks: kiosks },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query {
				getAllKiosks {
					HardwareID
					SerialNumber
					HardwareVersion
					CodeName
					Market
					HardwareManufacturer
					ManufacturerID
					Connectivity
					Lense
					Display
					CardReader
					SoftwareVersion
					IsOnline
					IsVirtualMeter
					Site {
						SiteID
						Name
						SiteType
					}
					Client {
						ClientID
						Name
					}
				}
			}
		`
	);

	const filteredData = useSearchFilter(kiosks, state.search, [
		"SerialNumber",
		"HardwareManufacturer",
		"ManufacturerID",
		"Connectivity",
		"SoftwareVersion",
		"Site.Name",
		"Client.Name",
	]);

	return (
		<div>
			{state.wizardOpen && (
				<AddKioskWizard
					close={() => {
						refetch();
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));
					}}
				/>
			)}
			<ReportWrapper
				{...props}
				title="Kiosks"
				data={filteredData}
				columns={columns}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button
							color="blue"
							style={{ marginLeft: 8 }}
							onClick={() => openWizard()}
						>
							<Plus size={20} /> Add Kiosk
						</Button>
					) : null
				}
				cursorColumn="KioskID"
			/>
		</div>
	);
}
