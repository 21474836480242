import gql from "graphql-tag";
import { useQueryData } from "../../../hooks";

export const useGetIntegrations = (context, organizationId) => {
	const {
		data: { getIntegrations: integrations },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getIntegrations(context: $context, organizationId: $organizationId) {
					IntegrationID
					IntegrationType
					EventType
					AuthType
					FetchRequest {
						RequestID
						Name
						URL
						Method
					}
					Sites {
						SiteID
						Name
					}
					Cameras {
						CameraID
						SerialNumber
					}
					Metadata {
						Interval
						Expiry
						Directory
						CSVConfig {
							Headers {
								Header
								Required
							}
							ExternalUserCode {
								Column
							}
							SiteID {
								Column
							}
							GroupName {
								Column
							}
							MappedValues {
								Column
								From
								To
							}
						}
					}
					IsEnabled
					IsDeleted
					LoggingEnabled
					IsSynchronous
				}
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		integrations,
		isLoading,
		refetch,
	};
};

export const useGetRequests = (context, organizationId) => {
	const {
		data: { getRequests: requests },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getRequests(context: $context, organizationId: $organizationId) {
					RequestID
					Name
					URL
					Method
					Params {
						Key
						Value
						Description
					}
					AuthorizationType
					Headers {
						Key
						Value
						Description
					}
					Transformations {
						Variable {
							Name
							Label
							Type
						}
						Name
						Label
						Transformation
						Template
						Format
						Hours
						CharacterLength
						Key
						Find
						Replace
						Mapping
						Digits
						TimestampBuilder {
							From
							Add {
								Days
								Hours
							}
						}
						Ternary {
							Condition
							IfTrue
							IfFalse
						}
					}
					Body
					BodyLanguage
					Response {
						Method
						Path
						Variable
						Type
						Label
						Description
						Predicate
					}
					Handler {
						Validation {
							Prop
							Object
							ObjectProp
							PropSource
							AbortRequest
						}
						Inserts {
							Object
							Properties {
								Prop
								PropSource
								ObjectProp
							}
						}
						Reports {
							Report
							Name
							Prop
						}
						Method
						Context {
							Properties {
								Prop
								PropSource
								ObjectProp
							}
						}
					}
					Rules {
						ValidateRequest {
							...RequestRuleInfo
						}
						PreRequest {
							...RequestRuleInfo
						}
						PostRequest {
							...RequestRuleInfo
						}
					}
					BasicAuthCredentials {
						Username
						Password
					}
					BearerToken
					SFTPConnection {
						Username
						Password
						PrivateKey
						Port
					}
					ExternallyConsumed
					ResponseBody
					ResponseBodyLanguage
				}
			}

			fragment RequestRuleInfo on RequestRule {
				Type
				Variable
				ResponseCode
				Condition
				Outcome
				Request
				UnsetVariable
				DerivedVariable
				ConditionalValue
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		requests,
		isLoading,
		refetch,
	};
};

export const useGetVariables = (context, organizationId) => {
	const {
		data: { getVariables: variableGroups },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getVariables(context: $context, organizationId: $organizationId) {
					Name
					Variables {
						VariableID
						Name
						Label
						Value
						Description
						Type
						IsSecret
					}
				}
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		variableGroups,
		isLoading,
		refetch,
	};
};

export const useGetApiKeys = (context, organizationId) => {
	const {
		data: { getApiKeys: apiKeys },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getApiKeys(context: $context, organizationId: $organizationId) {
					PublicAPIKeyID
					Name
					SiteIDs
					Scope
					IsEnabled
					Rules {
						Type
						Variable
						Duration
					}
					OrganizationID
				}
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		apiKeys,
		isLoading,
		refetch,
	};
};

export const useGetTemplates = () => {
	const {
		data: { getIntegrationTemplates: templates },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query {
				getIntegrationTemplates {
					IntegrationTemplateID
					Name
					Request {
						Name
						URL
						Method
					}
					Template
				}
			}
		`
	);

	return {
		templates,
		isLoading,
		refetch,
	};
};
