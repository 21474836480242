import * as Yup from "yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { colours, mediaSizes } from "../../styles";
import { Formik } from "formik";
import { MenuContext } from "../../context/menu-context";
import PageTitle from "../layout/PageTitle";
import PropTypes from "prop-types";
import StepIndicator from "./StepIndicator";
import { X } from "react-feather";
import _ from "lodash";
import styled from "styled-components";

const Wrapper = styled.div`
	background-color: ${colours.offWhite};
	bottom: 0;
	left: ${(props) => (props.menuCollapsed ? 56 : 256)}px;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	transition: 0.2s ease;
	z-index: 5;

	&:focus {
		outline: 0;
	}

	@media (max-width: ${mediaSizes.medium}px) {
		left: 0;
		top: 0;
	}
`;

const FormWrapper = styled.div`
	margin: 32px auto;
	max-width: ${(props) => props.width};
	padding: 32px;
`;

const TitleBar = styled.div`
	background-color: ${colours.highlightGrey};
	padding: 16px 32px;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 24px;
	top: 24px;
	transition: 0.2s ease;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const StepWrapper = styled.div`
	background-color: ${colours.highlightGrey};
	border-left: 4px solid ${colours.blue};
	padding: 32px;
`;

const StepTitle = styled.div`
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 32px;
`;

const StepText = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 1.3em;
`;

const EmptyLine = styled.div`
	height: 20px;
`;

const StepTextBold = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.3em;
`;
export { StepTitle, StepText, StepTextBold, EmptyLine };

WizardLayout.propTypes = {
	close: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	steps: PropTypes.arrayOf(PropTypes.func).isRequired,
	title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
	values: PropTypes.arrayOf(PropTypes.object).isRequired,
	wizardProps: PropTypes.object,
	hideStepIndicator: PropTypes.bool,
	rootTabIndex: PropTypes.bool,
};

function WizardLayout(props) {
	const [step, setStep] = useState(props.initialStep || 0);
	const [keyNext, setKeyNext] = useState(false);
	const {
		state: { menuCollapsed },
	} = useContext(MenuContext);
	const wizardLayout = useRef(null);

	useEffect(() => {
		// set a class on the document body to prevent overflow scrolling
		document.body.classList.add("modal-open");
		wizardLayout.current.focus();
		return () => {
			document.body.classList.remove("modal-open");
		};
	});

	const _values = props.values.reduce((result, item) => {
		result[item.name] = item.value;
		return result;
	}, {});

	const _schema = Yup.object().shape(
		props.values.reduce((result, item) => {
			result[item.name] = item.validator;
			return result;
		}, {})
	);

	return (
		<Formik
			initialValues={_values}
			validationSchema={_schema}
			onSubmit={props.onSubmit}
			validateOnBlur={false} // This is to prevent the form from validating on blur
			validateOnChange={false} // This is to prevent the form from validating on every keystroke
		>
			{(formProps) => {
				const title =
					typeof props.title === "function"
						? props.title(formProps.values)
						: props.title;
				const keyStrokeHandler = { keyNext, setKeyNext };
				const steps = props.steps.map((_step) =>
					_step({
						...formProps,
						wizardProps: props.wizardProps,
						close: props.close,
						next,
						previous,
						goTo,
						keyStrokeHandler,
						skip,
					})
				);

				function previous() {
					setStep(step === 0 ? 0 : step - 1);
				}

				function next() {
					setStep(step === steps.length - 1 ? steps.length : step + 1);
				}

				function skip(by) {
					setStep(Math.min(step + 1 + by, steps.length - 1));
				}

				function goTo(id) {
					const stepId = steps.findIndex((e) => e.id === id);

					if (stepId > -1) {
						setStep(stepId);
					}
				}

				const lastIndex = steps.length - 1;
				const currentStep = lastIndex < step ? steps[lastIndex] : steps[step];
				let width = "800px";
				if (props.widths) {
					const currentStepId = currentStep.id;

					let widthForStep = props.widths[currentStepId];
					if (typeof widthForStep === "object") {
						const value = formProps.values[currentStepId];
						const widthForSecondLevelStep = widthForStep[value];
						if (widthForSecondLevelStep) {
							width = widthForSecondLevelStep;
						}
					} else {
						width = widthForStep || width;
					}
				}

				function keyStrokeListner(e) {
					if (e.key === "Enter") {
						setKeyNext(true);
					}
				}

				return (
					<Wrapper
						menuCollapsed={menuCollapsed}
						tabIndex={
							_.isUndefined(props.rootTabIndex) ? -1 : props.rootTabIndex
						}
						onKeyUp={keyStrokeListner}
						ref={wizardLayout}
					>
						<TitleBar>
							<PageTitle>{title}</PageTitle>
							<CloseButton onClick={props.close}>
								<X size={40} />
							</CloseButton>
						</TitleBar>
						{steps && steps.length > 1 && !props.hideStepIndicator && (
							<StepIndicator steps={steps} step={step} />
						)}
						<FormWrapper width={width}>
							<form
								className="form"
								onSubmit={(event) => {
									event.preventDefault();
								}}
							>
								<div>
									<StepWrapper>{currentStep.render()}</StepWrapper>
									{currentStep.footer && currentStep.footer()}
								</div>
							</form>
						</FormWrapper>
					</Wrapper>
				);
			}}
		</Formik>
	);
}

export default WizardLayout;
