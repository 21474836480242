import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import React, { useState } from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import Input from "../../layout/Input";
import { isNil, trimStart } from "lodash";
import MultiChoiceButton from "../MultiChoiceButton";
import {
	AUTHORIZATION_OPTIONS,
	AUTHORIZATION_TYPES,
	COLORS_BY_REQUEST_METHOD,
	RULE_OPTIONS_BY_PROP,
	TIMESTAMP_FORMAT_OPTIONS,
	TRANSFORMATIONS,
} from "../../../helpers/constants";
import Repeater, { Icon } from "../../layout/Repeater";
import styled from "styled-components";
import Dropdown from "../../layout/Dropdown";
import { Editor } from "@monaco-editor/react";
import { Variables } from "../components/Variables";
import InputWithIcon from "../../layout/InputWithIcon";
import { PlusCircle } from "react-feather";
import { colours } from "../../../styles";
import Label from "../../layout/Label";
import Alert from "react-s-alert";
import Loader from "../../layout/Loader";
import { InputWithVariables } from "../components/InputWithVariables";
import { suggestableInputSummaryItem } from "../Generics";
import {
	CSVEditor,
	Mapper,
	KeyValuePairs,
	Ternary,
	TimestampBuilder,
	ParameterItem,
	FilterResponse,
	ReturnResponse,
	RepeaterStepSection,
	RepeaterStepItem,
} from "./components";
import {
	LANGUAGE_MODES,
	REPEATER_TYPES,
	RESPONSE_METHODS,
	RESPONSE_METHOD_LABELS,
} from "./constants";
import envConfig from "../../../api/environment";
const {
	config: { basePath },
} = envConfig;
import { bodyToObject } from "./helpers";

const typeByTransformation = {
	ConvertToNegativeFloat: "Float",
	ConvertToNegativeInteger: "Float",
	ConvertToString: "String",
	Cents: "Float",
	Format: "String",
	AddHours: "Timestamp",
	GenerateRandomString: "String",
	FromTemplate: "String",
};

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-bottom: 10px;
	gap: 20px;
`;

export const nameStep = ({ values, setFieldValue, next, goTo, close }) => {
	return {
		id: "name",
		label: "Name",
		render: () => (
			<div>
				<StepTitle>What is the name of this request?</StepTitle>

				<Input
					type="text"
					name="name"
					value={values.name || ""}
					onChange={(event) => {
						setFieldValue("name", trimStart(event.target.value));
					}}
				/>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={close}>
										Cancel
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={!values.name}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const methodStep =
	(setMethod) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: "method",
			label: "Type",
			render: () => (
				<div>
					<StepTitle>What type of request/connection is this?</StepTitle>

					{["GET", "POST", "SFTP"].map((method) => (
						<MultiChoiceButton
							key={method}
							selected={values.method === method}
							onClick={() => {
								setFieldValue("method", method);
								setMethod(method);
							}}
						>
							{method}
						</MultiChoiceButton>
					))}
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const externalStep =
	(setExternallyConsumed) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: "external",
			label: "External",
			render: () => (
				<div>
					<StepTitle>Will this request be consumed externally?</StepTitle>
					<MultiChoiceButton
						key="No"
						selected={!values.externallyConsumed}
						onClick={() => {
							setFieldValue("externallyConsumed", false);
							setExternallyConsumed(false);
							setFieldValue("urlPrefix", "");
						}}
					>
						No
					</MultiChoiceButton>
					<MultiChoiceButton
						key="Yes"
						selected={values.externallyConsumed}
						onClick={() => {
							setFieldValue("externallyConsumed", true);
							setExternallyConsumed(true);
							setFieldValue("urlPrefix", `${basePath}/shared/v1/api/`);
						}}
					>
						Yes
					</MultiChoiceButton>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const sftpPassKeyStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "sftpAuthMethod",
		label: values.sftpAuthMethod === "PrivateKey" ? "Private Key" : "Password",
		render: () => (
			<div>
				<StepTitle>Authenticate with a password or private key?</StepTitle>

				{[
					{ key: "Password", label: "Password" },
					{ key: "PrivateKey", label: "Private Key" },
				].map((option) => (
					<MultiChoiceButton
						key={option.key}
						selected={values.sftpAuthMethod === option.key}
						onClick={() => {
							setFieldValue("sftpAuthMethod", option.key);
						}}
					>
						{option.label}
					</MultiChoiceButton>
				))}

				{values.sftpAuthMethod === "Password" && (
					<>
						<StepText>Password</StepText>
						<InputWithVariables
							id="password"
							values={values}
							setFieldValue={setFieldValue}
						/>
					</>
				)}

				{values.sftpAuthMethod === "PrivateKey" && (
					<>
						<StepText style={{ marginBottom: "20px" }}>Private Key</StepText>
						<InputWithVariables
							id="privateKey"
							type="textarea"
							values={values}
							setFieldValue={setFieldValue}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const paramsStep = ({ values, setFieldValue, next, goTo, previous }) => {
	return {
		id: "params",
		label: "Params",
		render: () => (
			<div>
				<StepTitle>Do you need to pass any params to this request?</StepTitle>
				<MultiChoiceButton
					key="No"
					selected={!values.hasParams}
					onClick={() => {
						setFieldValue("hasParams", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					key="Yes"
					selected={values.hasParams}
					onClick={() => {
						setFieldValue("hasParams", true);
					}}
				>
					Yes
				</MultiChoiceButton>

				{values.hasParams && (
					<>
						<StepText style={{ marginBottom: "20px" }}>Params</StepText>
						<Repeater
							minusMode="all"
							items={values.params}
							add={() => {
								setFieldValue("params", [
									...values.params,
									{ key: "", value: "", description: "" },
								]);
							}}
							subtract={(index) => {
								const items = [...values.params];
								if (index > -1) items.splice(index, 1);
								setFieldValue("params", items);
							}}
							template={(param, index) => {
								return (
									<ParameterItem>
										{["key", "value", "description"].map((prop) => (
											<Input
												key={prop}
												useLabel={prop}
												value={param[prop]}
												onChange={(event) => {
													setFieldValue(
														`params.${index}.${prop}`,
														event.target.value
													);
												}}
											/>
										))}
									</ParameterItem>
								);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.hasParams && !values.params?.length}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const authorizationStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "authorization",
		label: "Authorization",
		render: () => {
			const authorizationOptions = AUTHORIZATION_OPTIONS;
			const credsOnChange = (auth) => {
				const headers = values.headers || [];
				setFieldValue("hasHeaders", true);
				setFieldValue("headers", [
					{
						key: "Authorization",
						value: auth,
						readonly: true,
					},
					...headers.filter((h) => h.key !== "Authorization"),
				]);
			};

			const updateAuthorization = (
				authorizationType,
				basicAuthUsername,
				basicAuthPassword,
				bearerToken
			) => {
				if (authorizationType === AUTHORIZATION_TYPES.BASIC) {
					credsOnChange(
						`Basic ${btoa(`${basicAuthUsername}:${basicAuthPassword}`)}`
					);
				} else if (authorizationType === AUTHORIZATION_TYPES.BEARER) {
					credsOnChange(`Bearer ${bearerToken}`);
				}
			};
			return (
				<div>
					<StepTitle>Does this request need authorization?</StepTitle>
					<MultiChoiceButton
						key="No"
						selected={!values.shouldAuthorize}
						onClick={() => {
							setFieldValue("shouldAuthorize", false);
							setFieldValue(
								"headers",
								values.headers?.filter((h) => h.key !== "Authorization") || []
							);
						}}
					>
						No
					</MultiChoiceButton>
					<MultiChoiceButton
						key="Yes"
						selected={values.shouldAuthorize}
						onClick={() => {
							setFieldValue("shouldAuthorize", true);
							updateAuthorization(
								values.authorizationType,
								values.basicAuthUsername,
								values.basicAuthPassword,
								values.bearerToken
							);
						}}
					>
						Yes
					</MultiChoiceButton>

					{values.shouldAuthorize && (
						<>
							<StepText style={{ marginBottom: "20px" }}>
								Authorization Type
							</StepText>
							<Dropdown
								name="authorizationType"
								options={authorizationOptions}
								value={authorizationOptions.find(
									(auth) => auth.value === values.authorizationType
								)}
								onChange={(value) => {
									setFieldValue("authorizationType", value?.value);
									updateAuthorization(
										value?.value,
										values.basicAuthUsername,
										values.basicAuthPassword,
										values.bearerToken
									);
								}}
							/>

							{values.authorizationType === AUTHORIZATION_TYPES.BASIC && (
								<>
									<StepText style={{ marginTop: "20px" }}>Username</StepText>
									<Input
										type="text"
										name="basicAuthUsername"
										value={values.basicAuthUsername || ""}
										onChange={(event) => {
											const username = trimStart(event.target.value);
											setFieldValue("basicAuthUsername", username);
											updateAuthorization(
												values.authorizationType,
												username,
												values.basicAuthPassword,
												values.bearerToken
											);
										}}
									/>
									<StepText style={{ marginTop: "20px" }}>Password</StepText>
									<Input
										type="password"
										name="basicAuthPassword"
										value={values.basicAuthPassword || ""}
										onChange={(event) => {
											const password = event.target.value;
											setFieldValue("basicAuthPassword", password);
											updateAuthorization(
												values.authorizationType,
												values.basicAuthUsername,
												password,
												values.bearerToken
											);
										}}
									/>
								</>
							)}

							{values.authorizationType === AUTHORIZATION_TYPES.BEARER && (
								<>
									<StepText style={{ marginTop: "20px" }}>Token</StepText>
									<Input
										type="text"
										name="bearerToken"
										value={values.bearerToken || ""}
										onChange={(event) => {
											const token = event.target.value;
											setFieldValue("bearerToken", token);
											updateAuthorization(
												values.authorizationType,
												values.basicAuthUsername,
												values.basicAuthPassword,
												token
											);
										}}
									/>
								</>
							)}
						</>
					)}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.shouldAuthorize && !values.authorizationType}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const headersStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "headers",
		label: "Headers",
		render: () => (
			<div>
				<StepTitle>Do you need to pass any headers to this request?</StepTitle>
				<MultiChoiceButton
					key="No"
					selected={!values.hasHeaders}
					onClick={() => {
						setFieldValue("hasHeaders", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					key="Yes"
					selected={values.hasHeaders}
					onClick={() => {
						setFieldValue("hasHeaders", true);
					}}
				>
					Yes
				</MultiChoiceButton>
				{values.hasHeaders && (
					<>
						<StepText style={{ marginBottom: "20px" }}>Headers</StepText>
						<Repeater
							minusMode="all"
							items={values.headers}
							add={() => {
								setFieldValue("headers", [
									...values.headers,
									{ key: "", value: "", description: "" },
								]);
							}}
							subtract={() => {
								const items = [...values.headers];
								items.pop();
								setFieldValue("headers", items);
							}}
							template={(header, index) => {
								return (
									<ParameterItem
										key={`header${index}`}
										style={{ marginBottom: "15px" }}
									>
										{["key", "value", "description"].map((prop) => (
											<Input
												key={`header${index}${prop}`}
												disabled={header.readonly}
												useLabel={prop}
												value={header[prop]}
												onChange={(event) => {
													setFieldValue(
														`headers.${index}.${prop}`,
														event.target.value
													);
												}}
											/>
										))}
									</ParameterItem>
								);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.hasParams && !values.params?.length}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const transformStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "transform",
		label: "Transform",
		render: () => {
			let variables = [
				{
					label: "Transform",
					options: values.transformations
						?.filter((trns) => trns.name)
						?.map((trns) => ({
							value: trns.name,
							label: trns.label || trns.name,
							type:
								trns.transformation?.value === "Ternary"
									? "Timestamp" // TODO: rework this.
									: typeByTransformation[trns.transformation.value] || "String",
						})),
				},
				...(values.variableGroups?.map((group) => ({
					label: group.name,
					options: group.variables.map((variable) => ({
						value: variable.value,
						label: variable.label || variable.value,
						type: variable.type,
					})),
				})) || []),
			];

			const isBoolean = (transformation) =>
				transformation.variable?.type === "Boolean";
			const isMappingBoolean = (transformation) =>
				isBoolean(transformation) &&
				transformation.transformation?.value === "MapValue";
			const isTimestamp = (transformation) =>
				transformation.variable?.type === "Timestamp";
			const isFormattingTimestamp = (transformation) =>
				isTimestamp(transformation) &&
				transformation.transformation?.value === "Format";
			const isAddingHours = (transformation) =>
				isTimestamp(transformation) &&
				transformation.transformation?.value === "AddHours";
			const actionIsCreatingVariable = (transformation) =>
				transformation.variable?.value === "CreateVariable";
			const actionIsGeneratingString = (transformation) =>
				actionIsCreatingVariable(transformation) &&
				transformation.transformation?.value === "GenerateRandomString";
			const actionIsGeneratingTimestamp = (transformation) =>
				actionIsCreatingVariable(transformation) &&
				transformation.transformation?.value === "GenerateTimestamp";
			const actionIsFromTemplate = (transformation) =>
				actionIsCreatingVariable(transformation) &&
				transformation.transformation?.value === "FromTemplate";
			const isHashing = (transformation) =>
				transformation.transformation?.value === "SHA256";
			const isFindAndReplace = (transformation) =>
				transformation.transformation?.value === "FindAndReplace";
			const isToFixed = (transformation) =>
				transformation.transformation?.value === "ToFixed";
			const ifTernary = (transformation) =>
				transformation.transformation?.value === "Ternary";
			const form = [
				{
					name: "variable",
					type: "dropdown",
					options: (transformation) => {
						const groups = [];

						for (let group of variables) {
							groups.push({
								label: group.label,
								options: group.options.filter(
									(o) => o.value !== transformation.name
								),
							});
						}

						return groups;
					},
				},
				{ name: "name", type: "input" },
				{ name: "label", type: "input" },
				{
					name: "transformation",
					type: "dropdown",
					options: (transformation) =>
						transformation.variable
							? TRANSFORMATIONS[transformation.variable?.type]
							: [],
					onChange: (value, index) => {
						switch (value.value) {
							case "MapValue":
								setFieldValue(`transformations.${index}.mapping`, {
									true: {
										value: "Yes",
										type: { value: "String", label: "String" },
									},
									false: {
										value: "No",
										type: { value: "String", label: "String" },
									},
								});

								break;
							case "Ternary":
								setFieldValue(`transformations.${index}.ternary`, {
									condition: "",
									ifTrue: values.transformations[index].variable.value,
									ifFalse: "",
								});
								break;
						}
					},
				},
				{
					name: "template",
					type: "input",
					show: isFormattingTimestamp,
				},
				{
					name: "format",
					type: "dropdown",
					options: () => TIMESTAMP_FORMAT_OPTIONS,
					show: isFormattingTimestamp,
				},
				{
					name: "mapping",
					type: "mapper",
					show: isMappingBoolean,
				},
				{
					name: "hours",
					type: "input",
					show: isAddingHours,
				},
				{
					name: "characterLength",
					label: "character length",
					type: "input",
					show: actionIsGeneratingString,
				},
				{
					name: "template",
					type: "input",
					show: actionIsFromTemplate,
				},
				{
					name: "key",
					type: "input",
					show: isHashing,
				},
				{
					name: "find",
					type: "input",
					show: isFindAndReplace,
				},
				{
					name: "replace",
					type: "input",
					show: isFindAndReplace,
				},
				{
					name: "digits",
					type: "input",
					show: isToFixed,
					min: "0",
					inputType: "number",
				},
				{
					name: "ternary",
					type: "ternary",
					show: ifTernary,
				},
				{
					name: "timestampBuilder",
					type: "timestampBuilder",
					show: actionIsGeneratingTimestamp,
				},
			];
			return (
				<div>
					<StepTitle>
						Do values in this request need to be transformed?
					</StepTitle>

					<MultiChoiceButton
						key="No"
						selected={!values.hasTransformations}
						onClick={() => {
							setFieldValue("hasTransformations", false);
						}}
					>
						No
					</MultiChoiceButton>
					<MultiChoiceButton
						key="Yes"
						selected={values.hasTransformations}
						onClick={() => {
							setFieldValue("hasTransformations", true);
						}}
					>
						Yes
					</MultiChoiceButton>
					{values.hasTransformations && (
						<>
							<StepText style={{ marginBottom: "20px" }}>
								Transformations
							</StepText>
							<Repeater
								minusMode="all"
								items={values.transformations || []}
								add={() => {
									setFieldValue("transformations", [
										...values.transformations,
										{
											variable: null,
											name: "",
											label: "",
											transformation: "",
											template: null,
											format: null,
											mapping: {},
											ternary: {},
											timestampBuilder: {},
											mode: "edit",
										},
									]);
								}}
								edit={(index) => {
									setFieldValue(`transformations.${index}.mode`, "edit");
								}}
								save={(index) => {
									setFieldValue(`transformations.${index}.mode`, "view");
								}}
								subtract={(index) => {
									const items = [...values.transformations];
									if (index > -1) items.splice(index, 1);
									setFieldValue("transformations", items);
								}}
								template={(transformation, index) => {
									return (
										<ParameterItem>
											{transformation.mode === "edit" ? (
												form.map((prop) => {
													const options = prop.options?.(transformation) ?? [];
													const show =
														!prop.show || prop.show?.(transformation);
													if (!show) return null;

													switch (prop.type) {
														case "dropdown":
															return (
																<Dropdown
																	style={{ width: "200px" }}
																	key={prop.name}
																	placeholder={prop.label || prop.name}
																	value={transformation[prop.name]}
																	options={options}
																	onChange={(value) => {
																		setFieldValue(
																			`transformations.${index}.${prop.name}`,
																			value
																		);
																		prop.onChange?.(value, index);
																	}}
																/>
															);
														case "input":
															return (
																<Input
																	key={prop.name}
																	type={prop.inputType}
																	useLabel={prop.label || prop.name}
																	value={transformation[prop.name]}
																	min={prop.min}
																	onChange={(event) => {
																		setFieldValue(
																			`transformations.${index}.${prop.name}`,
																			event.target.value
																		);
																	}}
																/>
															);
														case "mapper":
															return (
																<Mapper
																	key={prop.name}
																	mapping={transformation[prop.name]}
																	transformationIndex={index}
																	setFieldValue={setFieldValue}
																/>
															);
														case "ternary":
															return (
																<Ternary
																	key={prop.name}
																	ternary={transformation.ternary}
																	variables={variables}
																	transformationIndex={index}
																	setFieldValue={setFieldValue}
																/>
															);
														case "timestampBuilder":
															return (
																<TimestampBuilder
																	key={prop.name}
																	timestampBuilder={
																		transformation.timestampBuilder
																	}
																	transformationIndex={index}
																	setFieldValue={setFieldValue}
																/>
															);
														default:
															return null;
													}
												})
											) : (
												<span style={{ marginTop: "6px" }}>
													{TRANSFORMATIONS[transformation.variable?.type]
														?.find(
															(trns) =>
																trns.value ===
																transformation.transformation?.value
														)
														?.getDescription?.(
															{
																...transformation,
																variableName:
																	transformation.variable?.label ||
																	transformation.variable?.name,
																type: transformation.variable?.type,
																name:
																	transformation.label || transformation.name,
															},
															variables
														)}
												</span>
											)}
										</ParameterItem>
									);
								}}
							/>
						</>
					)}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

function validJson(json) {
	try {
		JSON.parse(json);
		return true;
	} catch (error) {
		return false;
	}
}

export const bodyStep =
	(editorRef) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: "body",
			label: "Body",
			render: () => {
				const formatDescription = {
					UTC: "in UTC",
					Timezone: "using Site Timezone",
				};
				const descriptionByTransformation = {
					ConvertToString: (transform) =>
						`Converted ${
							transform.variable.label || transform.variable.value
						} to String`,
					ConvertToNegativeFloat: (transform) =>
						`Converted ${
							transform.variable.label || transform.variable.value
						} to negative float value`,
					ConvertToNegativeInteger: (transform) =>
						`Converted ${
							transform.variable.label || transform.variable.value
						} to negative integer value`,
					Cents: (transform) =>
						`Converted ${
							transform.variable.label || transform.variable.value
						} from dollars to cents`,
					Format: (transform) =>
						`Formatted as ${transform.template} from ${
							transform.variable.label
						} ${formatDescription[transform.format.value]}`,
					AddHours: (transform) => {
						return `Adding ${transform.hours} Hour${
							transform.hours == 1 ? "" : "s"
						} to the ${transform.variable?.label}`;
					},
					GenerateRandomString: (transform) =>
						`Generating a random ${transform.characterLength} character string at runtime.`,
					FromTemplate: () => `Created from a string template.`,
				};
				const byTransformModel = (transform) => ({
					type: typeByTransformation[transform.transformation.value],
					description: `${
						descriptionByTransformation[transform.transformation.value]?.(
							transform
						) ||
						transform.label ||
						transform.value
					}`,
				});
				const transformModelByType = {
					Timestamp: byTransformModel,
					Integer: byTransformModel,
					Float: byTransformModel,
					Action: byTransformModel,
					String: (transform) => ({
						type: "String",
						description: `Base 64 encoding of ${
							transform.variable?.label || transform.variable?.name
						}`,
					}),
				};

				const insertInCodeBody = (value) => {
					const selection = editorRef.current.getSelection();
					if (!selection) {
						return;
					}

					const { selectionStartColumn, selectionStartLineNumber } = selection;

					const start = selectionStartLineNumber - 1;

					const lines = values.body.split("\n");
					const line = lines[start];
					lines[start] =
						line.substring(0, selectionStartColumn) +
						`"{{{${value}}}}"` +
						line.substring(selectionStartColumn);

					setFieldValue("body", lines.join("\n"));
				};

				return (
					<div>
						<StepTitle>
							{values.externallyConsumed
								? "What will an example payload look like for this request?"
								: "What do you want to send in the body of the request?"}
						</StepTitle>

						<FlexRow>
							<div>
								<StepText style={{ marginBottom: "10px" }}>Body</StepText>

								{values.languageMode !== LANGUAGE_MODES.URL_ENCODED && (
									<Editor
										height="40vh"
										width={values.externallyConsumed ? "660px" : "845px"}
										language={
											values.languageMode === LANGUAGE_MODES.MULTIPART_FORM_DATA
												? LANGUAGE_MODES.JSON
												: values.languageMode === LANGUAGE_MODES.SOAP
												? LANGUAGE_MODES.XML
												: values.languageMode
										}
										defaultValue={values.defaultLanguageModeValue}
										value={values.body}
										options={{ minimap: { enabled: false } }}
										onChange={(value) => {
											if (
												values.languageMode ===
													LANGUAGE_MODES.MULTIPART_FORM_DATA &&
												values.csvSchema.selectedCellIndex != null
											) {
												setFieldValue("csvSchema", {
													...values.csvSchema,
													selectedCellIndex: null,
												});
											}

											setFieldValue("body", value);
										}}
										onMount={(editor) => {
											editorRef.current = editor;
										}}
									/>
								)}

								{values.externallyConsumed ? null : (
									<>
										{values.languageMode ===
											LANGUAGE_MODES.MULTIPART_FORM_DATA && (
											<CSVEditor
												setFieldValue={setFieldValue}
												values={values}
												onAddToBody={(id) => insertInCodeBody(id)}
											/>
										)}

										{values.languageMode === LANGUAGE_MODES.URL_ENCODED && (
											<KeyValuePairs
												body={values.body}
												onChange={(body) => setFieldValue("body", body)}
												onCellFocused={(key) =>
													setFieldValue("urlEncodedSelectedCell", key)
												}
											/>
										)}

										<div style={{ marginTop: "20px" }}>
											{Object.values(LANGUAGE_MODES).map((choice) => (
												<MultiChoiceButton
													key={choice}
													selected={values.languageMode === choice}
													onClick={() => {
														setFieldValue("languageMode", choice);
														setFieldValue(
															"defaultLanguageModeValue",
															choice === "xml"
																? '<?xml version="1.0" encoding="utf-8"?>'
																: "{}"
														);
													}}
												>
													<div>{choice}</div>
												</MultiChoiceButton>
											))}
										</div>
									</>
								)}
							</div>
							{values.externallyConsumed ? null : (
								<div>
									<StepText style={{ marginBottom: "10px" }}>
										Variables
									</StepText>
									<Variables
										variableGroups={[
											values.hasTransformations
												? {
														name: "Transform",
														color: "purple",
														variables: values.transformations?.map(
															(transform) => {
																const trns =
																	transformModelByType[
																		transform.variable?.type
																	]?.(transform);
																return {
																	value: transform.name,
																	label: transform.label,
																	description: trns?.description
																		? ` ${trns?.description}`
																		: "",
																	type: trns?.type || "String",
																};
															}
														),
												  }
												: null,
											...values.variableGroups.filter(
												(grp) => grp.name !== "Actions"
											),
										].filter((g) => g)}
										onVariableClicked={(variable) => {
											if (
												values.languageMode ===
													LANGUAGE_MODES.MULTIPART_FORM_DATA &&
												values.csvSchema.selectedCellIndex != null
											) {
												const index = values.csvSchema.selectedCellIndex;
												if (
													index === null ||
													!values.csvSchema.values.length ||
													index >= values.csvSchema.values.length
												) {
													return;
												}

												const csvValues = [...values.csvSchema.values];
												csvValues[index] = `{{{${variable.value}}}}`;
												setFieldValue("csvSchema", {
													...values.csvSchema,
													values: csvValues,
												});
												return;
											}

											if (
												values.languageMode === LANGUAGE_MODES.URL_ENCODED &&
												values.urlEncodedSelectedCell != null
											) {
												const key = values.urlEncodedSelectedCell;
												const bodyObject = bodyToObject(values.body);
												bodyObject[key] = `{{{${variable.value}}}}`;
												setFieldValue("body", JSON.stringify(bodyObject));
												return;
											}

											insertInCodeBody(variable.value);
										}}
									/>
								</div>
							)}
						</FlexRow>
					</div>
				);
			},
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								disabled={
									[
										LANGUAGE_MODES.JSON,
										LANGUAGE_MODES.MULTIPART_FORM_DATA,
									].includes(values.languageMode) && !validJson(values.body)
								}
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const responseStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "response",
		label: "Response",
		render: () => (
			<div>
				<StepTitle>What should we do with the response?</StepTitle>

				{Object.keys(RESPONSE_METHOD_LABELS).map((responseMethod) => (
					<MultiChoiceButton
						key={responseMethod}
						selected={values.responseMethod === responseMethod}
						onClick={() => {
							const exists = values.response.some(
								(r) => r.method === responseMethod
							);
							if (!exists) {
								setFieldValue("response", [
									...values.response,
									{
										method: responseMethod,
										path: "",
										variable: "",
										label: "",
										description: "",
										type: "",
									},
								]);
							}
							setFieldValue("responseMethod", responseMethod);
						}}
					>
						{RESPONSE_METHOD_LABELS[responseMethod]}
					</MultiChoiceButton>
				))}

				{values.responseMethod === RESPONSE_METHODS.RETURN_RESPONSE && (
					<ReturnResponse values={values} setFieldValue={setFieldValue} />
				)}

				{values.responseMethod === RESPONSE_METHODS.MAP_TO_VARIABLE && (
					<>
						<StepText style={{ marginBottom: "20px" }}>
							Map Response to Variables
						</StepText>
						<Repeater
							minusMode="all"
							items={values.response.filter(
								(r) => r.method === RESPONSE_METHODS.MAP_TO_VARIABLE
							)}
							add={() => {
								setFieldValue("response", [
									...values.response,
									{
										path: "",
										variable: "",
										label: "",
										description: "",
										method: RESPONSE_METHODS.MAP_TO_VARIABLE,
									},
								]);
							}}
							subtract={(index) => {
								const items = [...values.response];
								if (index > -1) items.splice(index, 1);
								setFieldValue("response", items);
							}}
							template={(responseItem, index) => {
								const typeOptions = [
									"String",
									"Integer",
									"Float",
									"Timestamp",
								].map((t) => ({
									value: t,
									label: t,
								}));
								return (
									<ParameterItem>
										{["path", "variable", "type", "label", "description"].map(
											(prop) =>
												prop === "type" ? (
													<Dropdown
														key={prop}
														placeholder={prop}
														value={typeOptions.find(
															(o) => o.value === responseItem[prop]
														)}
														options={typeOptions ?? []}
														onChange={(value) => {
															setFieldValue(
																`response.${index}.${prop}`,
																value?.value
															);
														}}
													/>
												) : (
													<Input
														key={prop}
														useLabel={prop}
														value={responseItem[prop]}
														onChange={(event) => {
															setFieldValue(
																`response.${index}.${prop}`,
																event.target.value
															);
														}}
													/>
												)
										)}
									</ParameterItem>
								);
							}}
						/>
					</>
				)}

				<FilterResponse values={values} setFieldValue={setFieldValue} />
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const rulesStep = ({ values, setFieldValue, next, goTo, previous }) => {
	return {
		id: "rules",
		label: "Rules",
		render: () => {
			const derivedVariableOptions = values.variableGroups
				?.filter(
					(group) => !["System", "Custom", "Actions"].includes(group.name)
				)
				?.map((group) => ({
					label: group.name,
					options: group.variables.map((variable) => ({
						value: variable.value,
						label: variable.label || variable.value,
						type: variable.type,
					})),
				}));
			return (
				<div>
					<StepTitle>Does this request have any rules?</StepTitle>

					<MultiChoiceButton
						key="No"
						selected={!values.hasRules}
						onClick={() => {
							setFieldValue("hasRules", false);
						}}
					>
						No
					</MultiChoiceButton>
					<MultiChoiceButton
						key="Yes"
						selected={values.hasRules}
						onClick={() => {
							setFieldValue("hasRules", true);
						}}
					>
						Yes
					</MultiChoiceButton>
					{values.hasRules && (
						<>
							{[
								{
									prefix: "validate",
									runtime: "before",
									header: "Validation Request Rules",
									rules: values.validateRequestRules,
								},
								{
									prefix: "pre",
									runtime: "before",
									header: "Pre-request Rules",
									rules: values.preRequestRules,
								},
								{
									prefix: "post",
									header: "Post-request Rules",
									runtime: "after",
									rules: values.postRequestRules,
								},
							].map((ruleModel) => (
								<React.Fragment key={`ruleModel${ruleModel.prefix}`}>
									<StepText>
										<FlexRow style={{ alignItems: "center" }}>
											{ruleModel.header}{" "}
											<span style={{ fontSize: "18px", fontWeight: "200" }}>
												(these rules are evaluated{" "}
												<strong>{ruleModel.runtime}</strong> the request is run)
											</span>
											{!ruleModel.rules?.length && (
												<Icon>
													<PlusCircle
														onClick={() => {
															setFieldValue(`${ruleModel.prefix}RequestRules`, [
																{ type: "", condition: "", outcome: "" },
															]);
														}}
														color={colours.blue}
													/>
												</Icon>
											)}
										</FlexRow>
									</StepText>
									<Repeater
										minusMode="all"
										canRemoveFirst={true}
										items={ruleModel.rules}
										add={() => {
											setFieldValue(`${ruleModel.prefix}RequestRules`, [
												...ruleModel.rules,
												{ type: "", condition: "", outcome: "" },
											]);
										}}
										subtract={(index) => {
											const items = [...ruleModel.rules];
											if (index > -1) items.splice(index, 1);
											setFieldValue(`${ruleModel.prefix}RequestRules`, items);
										}}
										template={(rule, index) => {
											return (
												<React.Fragment>
													<span
														style={{
															fontSize: "20px",
															marginTop: "10px",
															marginRight: "10px",
														}}
													>
														{ruleModel.prefix === "validate" ? "assert" : "if"}
													</span>
													<ParameterItem>
														{[
															{
																key: "type",
																label: "type",
																options: RULE_OPTIONS_BY_PROP.TYPE,
																width: "180px",
															},
															{
																key: "variable",
																label: "variable",
																canShow: () => rule.type === "Variable",
																options: values.variables,
																width: "200px",
															},
															{
																key: "derivedVariable",
																label: "variable",
																canShow: () => rule.type === "DerivedVariable",
																options: derivedVariableOptions,
																width: "200px",
																onChange: (value) => value,
																value: () => rule.derivedVariable,
															},
															{
																key: "condition",
																label: "condition",
																canShow: () => rule.type !== "True",
																options: RULE_OPTIONS_BY_PROP.CONDITION,
															},
															{
																key: "conditionalValue",
																label: "value",
																canShow: () =>
																	rule.type === "DerivedVariable" &&
																	rule.condition === "Equals",
																type: "mixed",
															},
															{
																key: "responseCode",
																label: "response code",
																canShow: () =>
																	rule.type === "ResponseCode" &&
																	rule.condition === "Equals",
																options: [
																	{ value: "400", label: "400 Bad Request" },
																	{ value: "401", label: "401 Unauthorized" },
																	{ value: "403", label: "403 Forbidden" },
																	{ value: "404", label: "404 Not Found" },
																	{
																		value: "408",
																		label: "408 Request Timeout",
																	},
																],
																width: "200px",
															},
															{
																key: "responseCode",
																label: "response code",
																canShow: () =>
																	rule.type === "ResponseCode" &&
																	rule.condition === "NotEquals",
																options: [
																	{ value: "2**", label: "2** (Successful)" },
																],
																width: "200px",
															},
															{
																key: "outcome",
																label: "outcome",
																options: RULE_OPTIONS_BY_PROP.OUTCOME,
																canShow: () => ruleModel.prefix !== "validate",
															},
															{
																key: "request",
																label: "request",
																canShow: () =>
																	rule.outcome === "Request" &&
																	ruleModel.prefix !== "validate",
																options: values.requests,
																width: "200px",
															},
															{
																key: "unsetVariable",
																label: "variable",
																canShow: () => rule.outcome === "UnsetVariable",
																options: values.variables,
																width: "200px",
															},
														].map((prop) => {
															if (prop.canShow && !prop.canShow()) {
																return null;
															}

															let type = prop.type;
															let dropdownOptions = prop.options ?? [];
															if (
																prop.type === "mixed" &&
																rule.derivedVariable
															) {
																let derivedVariable;
																for (let group of derivedVariableOptions) {
																	derivedVariable = group.options.find(
																		(o) => o.value === rule.derivedVariable
																	);
																	if (derivedVariable) break;
																}

																if (derivedVariable?.type === "Boolean") {
																	type = "dropdown";
																	dropdownOptions = [
																		{ value: true, label: "true" },
																		{ value: false, label: "false" },
																	];
																} else {
																	type = "input";
																}
															}

															if (type === "input") {
																return (
																	<InputWithIcon
																		key={prop.key}
																		value={rule[prop.key]}
																		onFormat={(value) => {
																			setFieldValue(
																				`${ruleModel.prefix}RequestRules.${index}.${prop.key}`,
																				value
																			);
																		}}
																	/>
																);
															}
															let dropdownValue;

															if (dropdownOptions[0]?.options) {
																for (const group of dropdownOptions) {
																	dropdownValue = group.options.find(
																		(o) => o.value === rule[prop.key]
																	);
																	if (dropdownValue) break;
																}
															} else {
																dropdownValue = dropdownOptions.find(
																	(o) => o.value == rule[prop.key]
																);
															}
															return (
																<Dropdown
																	style={{ width: prop.width ?? "150px" }}
																	key={prop.key}
																	placeholder={prop.label}
																	value={dropdownValue}
																	options={dropdownOptions}
																	onChange={(value) => {
																		setFieldValue(
																			`${ruleModel.prefix}RequestRules.${index}.${prop.key}`,
																			value?.value
																		);
																	}}
																/>
															);
														})}
													</ParameterItem>
												</React.Fragment>
											);
										}}
									/>
								</React.Fragment>
							))}
						</>
					)}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

function newInsertProperty() {
	return {
		objectProp: "",
		prop: "",
		propSource: "",
		mode: "edit",
	};
}

function InsertProperties(props) {
	const { values, insertIndex, setFieldValue, payloadProps } = props;

	return (
		<Repeater
			style={{ marginLeft: "50px" }}
			minusMode="all"
			items={values.inserts[insertIndex].properties}
			add={() => {
				setFieldValue(`inserts.${insertIndex}.properties`, [
					...values.inserts[insertIndex].properties,
					newInsertProperty(),
				]);
			}}
			subtract={(index) => {
				const properties = [...values.inserts[insertIndex].properties];
				if (index > -1) properties.splice(index, 1);
				setFieldValue(`inserts.${insertIndex}.properties`, properties);
			}}
			save={(index) => {
				setFieldValue(
					`inserts.${insertIndex}.properties.${index}.mode`,
					"view"
				);
			}}
			edit={(index) => {
				setFieldValue(
					`inserts.${insertIndex}.properties.${index}.mode`,
					"edit"
				);
			}}
			template={(param, index) => {
				return (
					<ParameterItem>
						<RepeaterStepItem
							index={index}
							repeatedItem={param}
							config={{
								name: `inserts.${insertIndex}.properties`,
								savedView: () => (
									<>
										Map <strong>{param.objectProp}</strong> to{" "}
										<strong>{param.prop}</strong> from{" "}
										<strong>{param.propSource}</strong>
									</>
								),
							}}
							setFieldValue={setFieldValue}
							optionsMap={{
								objectProp: {
									type: REPEATER_TYPES.SELECT,
									prefix: "Map",
									options: {
										ParkingSession: [
											"ExternalID",
											"KioskID",
											"SiteID",
											"MachineID",
											"TxnID",
											"PurchaseTime",
											"StartTime",
											"ExpiryTime",
											"Plate",
											"MOP",
											"Amount",
											"TariffType",
											"CustomData",
										],
										Transaction: [
											"ExternalID",
											"KioskID",
											"SiteID",
											"MachineID",
											"MOP",
											"TxnType",
											"Approval",
											"TxnID",
											"Amount",
											"TxnTime",
											"TxnExpiry",
											"Plate",
											"RateID",
											"CardType",
											"CardNumber",
											"AuthCode",
											"DpsTxnRef",
										],
										Status: [
											"ExternalID",
											"KioskID",
											"SiteID",
											"MachineID",
											"Status",
											"Time",
											"ErrorFlags",
											"WarningFlags",
										],
										Rate: ["ExternalID", "SiteID", "Name", "Amount"],
									}[values.inserts[insertIndex].object],
								},
								propSource: {
									type: REPEATER_TYPES.SELECT,
									prefix: "to",
									options: ["Payload", "Context"],
								},
								prop: {
									type: REPEATER_TYPES.SELECT,
									options: {
										Payload: payloadProps,
										Context: ["KioskID", "SiteID"],
									}[param.propSource],
								},
							}}
						/>
					</ParameterItem>
				);
			}}
		/>
	);
}

function InsertHandle(props) {
	const { values, setFieldValue, payloadProps } = props;

	return (
		<Repeater
			minusMode="all"
			items={values.inserts}
			add={() => {
				setFieldValue("inserts", [
					...values.inserts,
					{
						object: "",
						mode: "edit",
						properties: [newInsertProperty()],
					},
				]);
			}}
			subtract={(index) => {
				const items = [...values.inserts];
				if (index > -1) items.splice(index, 1);
				setFieldValue("inserts", items);
			}}
			save={(index) => {
				setFieldValue(`inserts.${index}.mode`, "view");
			}}
			edit={(index) => {
				setFieldValue(`inserts.${index}.mode`, "edit");
			}}
			template={(param, index) => {
				return (
					<>
						<ParameterItem>
							<RepeaterStepItem
								index={index}
								repeatedItem={param}
								config={{
									name: "inserts",
									savedView: (record) => (
										<>
											Create a <strong>{record.object}</strong> with the
											following fields
										</>
									),
								}}
								setFieldValue={setFieldValue}
								optionsMap={{
									object: {
										type: REPEATER_TYPES.SELECT,
										options: [
											"ParkingSession",
											"Transaction",
											"Status",
											"Rate",
										],
										prefix: "Create a",
									},
									fields: {
										type: REPEATER_TYPES.REPEATER,
										prefix: "with the following fields",
									},
								}}
							/>
						</ParameterItem>

						<InsertProperties
							values={values}
							setFieldValue={setFieldValue}
							insertIndex={index}
							payloadProps={payloadProps}
						/>
					</>
				);
			}}
		/>
	);
}

function Handler({ values, setFieldValue, payloadProps, style }) {
	const config = [
		{
			name: "validation",
			title: "Validation rules",
			subTitle: (
				<>
					(these rules are evaluated <strong>before</strong> the request is
					handled, and in sequential order)
				</>
			),
			newModel: () => ({
				prop: "",
				object: "",
				objectProp: "",
				mode: "edit",
				propSource: "Payload",
				abortRequest: true,
			}),
			getOptionsMap: (item) => ({
				object: {
					options: ["Kiosk"],
					prefix: "Find any",
					type: REPEATER_TYPES.SELECT,
				},
				objectProp: {
					options: ["SerialNumber", "IsVirtualMeter"],
					placeholder: "object prop",
					prefix: "where",
					type: REPEATER_TYPES.SELECT,
				},
				propSource: {
					type: REPEATER_TYPES.SELECT,
					prefix: "equals",
					options: ["Payload", "Literal"],
					placeholder: "source",
				},
				prop: {
					type:
						item.propSource === "Payload"
							? REPEATER_TYPES.SELECT
							: REPEATER_TYPES.LITERAL,
					options: {
						Payload: payloadProps,
					}[item.propSource],
				},
				abortRequest: {
					type: REPEATER_TYPES.SELECT,
					options: [
						{ label: "false", value: false },
						{ label: "true", value: true },
					],
					prefix: "else abort request",
					placeholder: "abort",
				},
			}),
			savedView: (validationItem) => (
				<>
					Find any <strong>{validationItem.object}</strong> where{" "}
					<strong>{validationItem.objectProp}</strong> equals{" "}
					<strong>{validationItem.prop}</strong>
					{validationItem.propSource === "Payload" && (
						<>
							{" "}
							from <strong>payload</strong>
						</>
					)}
					{!isNil(validationItem.abortRequest) ? (
						<>
							{", "}
							{validationItem.abortRequest ? "" : "don't "}
							<strong>abort</strong> if not found
						</>
					) : null}
				</>
			),
		},
		{
			name: "contextProperties",
			title: "Response Context",
			marginLeft: "50px",
			controlPlacement: "left",
			newModel: () => ({
				objectProp: "",
				prop: "",
				propSource: "",
				mode: "edit",
			}),
			getOptionsMap: (item) => ({
				objectProp: {
					type: REPEATER_TYPES.SELECT,
					prefix: "Map",
					options: ["ExternalSiteID", "LeaseParkID"],
				},
				propSource: {
					type: REPEATER_TYPES.SELECT,
					prefix: "to",
					options: ["Payload", "Literal"],
				},
				prop: {
					type:
						item.propSource === "Payload"
							? REPEATER_TYPES.SELECT
							: REPEATER_TYPES.LITERAL,
					options: {
						Payload: payloadProps,
						Literal: ["KioskID", "SiteID"],
					}[item.propSource],
				},
			}),
			savedView: (item) => (
				<>
					Map <strong>{item.objectProp}</strong> to <strong>{item.prop}</strong>{" "}
					from <strong>{item.propSource}</strong>
				</>
			),
		},
		//TODO: if we want to configure the reports dynamically, then we need to provide the config here
		// {
		// 	name: "reports",
		// 	title: "Show in reports",
		// 	newModel: () => ({ report: "", name: "", prop: "", mode: "edit" }),
		// 	getOptionsMap: (item, index) => ({
		// 		report: {
		// 			options: ["Transient"],
		// 			prefix: "Add a column to the",
		// 			type: REPEATER_TYPES.SELECT,
		// 		},
		// 		name: {
		// 			type: "text",
		// 			placeholder: "name",
		// 			prefix: "report named",
		// 			type: REPEATER_TYPES.SELECT,
		// 		},
		// 		prop: {
		// 			options: payloadProps,
		// 			prefix: "with the value from",
		// 			type: REPEATER_TYPES.SELECT,
		// 		},
		// 	}),
		// 	savedView: (column, index) => (
		// 		<>
		// 			Add a column to the <strong>{column.report}</strong> report named{" "}
		// 			<strong>{column.name}</strong> with the value from{" "}
		// 			<strong>{column.prop}</strong>
		// 		</>
		// 	),
		// },
		// ,
	];

	return (
		<div style={style}>
			<StepText style={{ marginBottom: "1rem" }}>
				What should we do with the request body?
			</StepText>

			{values.externallyConsumed && (
				<FilterResponse values={values} setFieldValue={setFieldValue} />
			)}

			{config.map((item) => (
				<RepeaterStepSection
					key={`repeat-step-section-${item.name}`}
					items={values[item.name] || []}
					item={item}
					setFieldValue={setFieldValue}
					values={values}
				/>
			))}

			<InsertHandle
				values={values}
				setFieldValue={setFieldValue}
				payloadProps={payloadProps}
			/>
		</div>
	);
}

function safeJsonParse(json) {
	try {
		return JSON.parse(json);
	} catch {
		return {};
	}
}

export const mappingStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "handler",
		label: "Handler",
		render: () => {
			const body = values.externallyConsumed
				? values.body
				: values.responseBody;

			const payloadProps = Object.keys(body ? safeJsonParse(body) : {});

			return (
				<div>
					<StepTitle>Are further actions required for the response?</StepTitle>

					{[
						{ label: "No", value: "Nothing" },
						{ label: "Yes", value: "Handle" },
					].map((option) => (
						<MultiChoiceButton
							key={option.label}
							selected={values.handlerMethod === option.value}
							onClick={() => {
								setFieldValue("handlerMethod", option.value);
							}}
						>
							{option.label}
						</MultiChoiceButton>
					))}

					{values.handlerMethod === "Handle" && (
						<>
							<StepText style={{ marginBottom: "1rem" }}>
								{`What will an example payload look like for this ${
									values.externallyConsumed ? "request" : "response"
								}?`}
							</StepText>

							<Editor
								height="40vh"
								width={"600px"}
								language={LANGUAGE_MODES.JSON}
								defaultValue={values.defaultLanguageModeValue}
								value={body}
								options={{ minimap: { enabled: false } }}
								onChange={(value) => {
									setFieldValue(
										values.externallyConsumed ? "body" : "responseBody",
										value
									);
								}}
							/>

							<Handler
								values={values}
								setFieldValue={setFieldValue}
								payloadProps={payloadProps}
								style={{ marginTop: "1rem" }}
							/>
						</>
					)}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const summaryStep =
	(attemptFileTransferConnection) =>
	({
		close,
		goTo,
		handleSubmit,
		isSubmitting,
		setFieldValue,
		values,
		wizardProps,
	}) => {
		const [connectionLoading, setConnectionLoading] = useState(false);
		const ruleCount =
			(values.validateRequestRules?.length || 0) +
			(values.preRequestRules?.length || 0) +
			(values.postRequestRules?.length || 0);
		const customVariables = values.variableGroups?.find(
			(group) => group.name === "Custom"
		)?.variables;

		const suggestableSummary = (key, title, edit) =>
			suggestableInputSummaryItem(
				key,
				title,
				edit,
				customVariables,
				values,
				setFieldValue,
				goTo
			);
		const isSFTP = values.method === "SFTP";
		const externallyConsumed = values.externallyConsumed;

		const nameSummaryItem = {
			title: "Name",
			value: values.name,
			key: "name",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("name");
			},
		};
		const methodSummaryItem = {
			title: "Type",
			value: (
				<Label color={COLORS_BY_REQUEST_METHOD[values.method]}>
					{values.method}
				</Label>
			),
			key: "method",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("method");
			},
		};
		const items = [
			nameSummaryItem,
			methodSummaryItem,
			...(isSFTP
				? []
				: [
						{
							title: "External",
							value: values.externallyConsumed ? "Yes" : "No",
							key: "external",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("external");
							},
						},
						suggestableSummary("url", "URL"),
						externallyConsumed
							? null
							: {
									title: "Params",
									value:
										values.hasParams && values.params?.length
											? `${values.params?.length} Parameter${
													values.params?.length === 1 ? "" : "s"
											  } Set`
											: "None",
									key: "params",
									edit: () => {
										setFieldValue("editFromSummary", true);
										goTo("params");
									},
							  },
						{
							title: "Authorization",
							value:
								values.shouldAuthorize && values.authorizationType
									? AUTHORIZATION_OPTIONS.find(
											(o) => o.value === values.authorizationType
									  )?.label
									: "No",
							key: "authorization",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("authorization");
							},
						},
						externallyConsumed
							? null
							: {
									title: "Headers",
									value:
										values.hasHeaders && values.headers?.length
											? `${values.headers?.length} Header${
													values.headers?.length === 1 ? "" : "s"
											  } Set`
											: "None",
									key: "headers",
									edit: () => {
										setFieldValue("editFromSummary", true);
										goTo("headers");
									},
							  },
						externallyConsumed
							? null
							: {
									title: "Transform",
									value: values.hasTransformations
										? `${values.transformations?.length} Transformation${
												values.transformations?.length === 1 ? "" : "s"
										  } Set`
										: "No",
									key: "transform",
									edit: () => {
										setFieldValue("editFromSummary", true);
										goTo("transform");
									},
							  },
						values.method === "POST" && !externallyConsumed
							? {
									title: "Body",
									value: "Click edit to view details",
									key: "body",
									edit: () => {
										setFieldValue("editFromSummary", true);
										goTo("body");
									},
							  }
							: null,

						externallyConsumed
							? null
							: {
									title: "Response",
									value: RESPONSE_METHOD_LABELS[values.responseMethod],
									key: "response",
									edit: () => {
										setFieldValue("editFromSummary", true);
										goTo("response");
									},
							  },
						{
							title: "Handler",
							value: "Click edit to view details",
							key: "handler",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("handler");
							},
						},
						externallyConsumed
							? null
							: {
									title: "Rules",
									value: values.hasRules
										? `${ruleCount} Rule${ruleCount === 1 ? "" : "s"} Set`
										: "None",
									key: "rules",
									edit: () => {
										setFieldValue("editFromSummary", true);
										goTo("rules");
									},
							  },
				  ]),
			...(isSFTP
				? [
						suggestableSummary("hostname", "Hostname"),
						suggestableSummary("username", "Username"),
						values.sftpAuthMethod === "PrivateKey"
							? suggestableSummary(
									"privateKey",
									"Private Key",
									"sftpAuthMethod"
							  )
							: suggestableSummary("password", "Password", "sftpAuthMethod"),
						suggestableSummary("port", "Port"),
				  ]
				: []),
		];

		return {
			id: "summary",
			label: "Summary",
			render: () => (
				<div>
					<StepTitle>Summary</StepTitle>
					<SummaryTable
						valueStyle={{ whiteSpace: "none" }}
						items={items.filter((i) => i)}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="previous" onClick={close} color="blue">
								Cancel
							</Button>,
						]}
						rightItems={[
							isSFTP ? (
								<Button
									color="blue"
									disabled={connectionLoading}
									onClick={async () => {
										try {
											setConnectionLoading(true);
											await attemptFileTransferConnection({
												variables: {
													hostname: values.hostname,
													username: values.username,
													password:
														values.sftpAuthMethod === "Password"
															? values.password
															: null,
													privateKey:
														values.sftpAuthMethod === "PrivateKey"
															? values.privateKey
															: null,
													port: values.port,
												},
											});
											Alert.success("The connection attempt was successful");
										} catch (error) {
											Alert.error("The connection attempt was unsuccessful ");
										} finally {
											setConnectionLoading(false);
										}
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-around",
											alignItems: "center",
											gap: "20px",
										}}
									>
										{connectionLoading && <Loader size={16} thickness={2} />}
										{connectionLoading
											? "Testing Connection"
											: "Test Connection"}
									</div>
								</Button>
							) : null,
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting}
							>
								{wizardProps.mode === "edit" ? "Update" : "Create"}
							</Button>,
						].filter((button) => button)}
					/>
				);
			},
		};
	};

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>Are you sure you want to delete this request?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const duplicateStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "duplicate",
	label: "Duplicate",
	render: () => (
		<div>
			<StepText>Are you sure you want to duplicate this request?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Duplicate
				</Button>,
			]}
		/>
	),
});
