import React, { useState } from "react";
import { booleanValueFormatterColour } from "../../components/layout/TableLayout";
import { fetchDataCallback, paginatedState } from "../../helpers/pagination";
import { useMutateData, usePermissions, useQueryData } from "../../hooks";
import Alert from "react-s-alert";
import Card from "../../components/layout/Card";
import FlexWrapper from "../../components/layout/FlexWrapper";
import ItemDropDownOptions from "../../components/billing/ItemDropDownOptions";
import Label from "../../components/layout/Label";
import RefundDetails from "../../components/details-pages/refund/RefundDetails";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import StatCard from "../../components/layout/StatCard";
import TransactionDetails from "../../components/details-pages/users/TransactionDetails";
import TransactionWizard from "../../components/wizards/transaction-wizard/TransactionWizard";
import { colours } from "../../styles";
import { format } from "../../helpers";
import gql from "graphql-tag";
import { map } from "lodash";
import moment from "moment";

const columns = (props) => [
	{
		id: "IsPaid",
		Header: "Paid",
		accessor: "IsPaid",
		Cell: (cellProps) =>
			cellProps.row.original.WasVoided ? (
				<Label color="blue">Void</Label>
			) : (
				booleanValueFormatterColour({
					value: cellProps.row.original.IsPaid,
				})
			),
		width: 60,
	},
	{
		id: "SiteNames",
		Header: "Site(s)",
		accessor: "_siteNames",
		highlightCell: (cellProps) => cellProps.row.original._siteNames,
		disableSortBy: true,
	},
	{
		id: "_transactionId",
		Header: "Transaction ID",
		accessor: "_transactionId",
		highlightCell: (cellProps) => cellProps.row.original._transactionId,
	},
	{
		id: "ItemType",
		Header: "Type",
		accessor: "ItemTypeFormatted",
		highlightCell: (cellProps) => cellProps.row.original.ItemTypeFormatted,
		disableSortBy: true,
	},
	{
		id: "BaseAmount",
		Header: "Parking Charge",
		accessor: "_parkingChargeSorting",
		Cell: ({ row: { original } }) => {
			const { BaseAmount, TransactionType, RefundedAmount } = original;

			const amount = TransactionType === "Refund" ? RefundedAmount : BaseAmount;
			return format.money(amount, false, true, false, TransactionType);
		},
	},
	{
		id: "OperatorFees",
		Header: "Credit Card Fee",
		accessor: "operatorFeesSorting",
		Cell: (cellProps) =>
			format.money(
				cellProps.row.original.OperatorFees,
				false,
				true,
				false,
				cellProps.row.original.TransactionType
			),
	},
	{
		id: "InugoFee",
		Header: "Convenience Fee",
		accessor: "inugoFeeSorting",
		Cell: (cellProps) =>
			format.money(
				cellProps.row.original.InugoFee,
				false,
				true,
				false,
				cellProps.row.original.TransactionType
			),
	},
	{
		id: "ValidationDiscount",
		Header: "Validation Discount",
		accessor: "validationDiscountSorting",
		Cell: (cellProps) =>
			format.money(
				cellProps.row.original.ValidationDiscount,
				false,
				true,
				false,
				cellProps.row.original.TransactionType
			),
		disableSortBy: true,
	},
	{
		id: "TotalAmount",
		Header: "Total User Charge",
		accessor: "totalAmountSorting",
		Cell: (cellProps) =>
			format.money(
				cellProps.row.original.TotalAmount,
				false,
				true,
				false,
				cellProps.row.original.TransactionType
			),
		className: "bold",
	},
	{
		id: "TaxAmount",
		Header: "Tax",
		accessor: "TaxAmount",
		Cell: (cellProps) =>
			format.money(
				cellProps.row.original.TaxAmount,
				false,
				true,
				false,
				cellProps.row.original.TransactionType
			),
		disableSortBy: true,
	},
	{
		id: "RefundedAmount",
		Header: "Refunded",
		accessor: "refundedAmountSorting",
		Cell: (cellProps) =>
			cellProps.row.original.TransactionType === "Refund"
				? format.money(cellProps.row.original.RefundedAmount, false, true, true)
				: "",
	},
	{
		id: "MethodOfPayment",
		Header: "Method Of Payment",
		accessor: "MethodOfPayment",
		highlightCell: (cellProps) => cellProps.row.original.MethodOfPayment,
		disableSortBy: true,
	},
	{
		id: "CreditCardType",
		Header: "Credit Card Type",
		accessor: "CreditCardType",
		highlightCell: (cellProps) =>
			cellProps.row.original.CreditCardType || "Unknown",
	},
	{
		id: "PaidOn",
		Header: "Paid On",
		accessor: "paidOnSorting",
		Cell: (cellProps) => cellProps.row.original.PaidOnFormatted || "",
		className: "bold",
	},
	{
		id: "RemittedOn",
		Header: "Settled On",
		accessor: "RemittedOn",
		Cell: (cellProps) => cellProps.row.original.RemittedOnFormatted || "",
		className: "bold",
	},
	{
		id: "ReceiptID",
		Header: "Receipt",
		accessor: "transactionIdSorting",
		Cell: (cellProps) => cellProps.row.original.TransactionID || "",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			cellProps.row.original._transactionId.startsWith("VP") ? null : (
				<ItemDropDownOptions
					isRevenue={true}
					cellProps={cellProps.row}
					permissions={props.permissions}
					showTransactionDetails={props.showTransactionDetails}
					showTransactionWizard={props.showTransactionWizard}
					showRefundDetails={props.showRefundDetails}
				/>
			),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const defaultSorting = [
	{
		id: "PaidOn",
		desc: true,
	},
];

export default function RevenueContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			search: "",
		},
		selectedSiteId: null,
		reportStyleOverride: {},
		transactionWizardMode: null,
		transactionWizardTransaction: null,
		user: null,
		bulkDownloadWizardOpen: false,
	});

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	async function updateOptions(options) {
		setState((_state) => ({ ..._state, initialLoad: false, options }));
	}

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	function formatData(_data) {
		return (
			map(_data, (d) => {
				const _paidStatus = d.WasVoided
					? "Void"
					: d.IsPaid
					? "Paid"
					: "Not Paid";
				const _isValidatedSorting = d.Validations ? 1 : 0;
				const _isValidated = d.Validations ? "Yes" : "No";
				const _parkingChargeSorting = d.BaseAmount || 0;
				const _parkingCharge = format.money(d.BaseAmount);
				const _operatorFees = format.money(d.OperatorFees);
				const _inugoFee = format.money(d.InugoFee);
				const _totalAmount = format.money(d.TotalAmount);
				const _refundedAmount = format.money(
					d.RefundedAmount,
					false,
					false,
					true,
					d.TransactionType
				);
				const _siteNames = d.SiteNames;
				const transactionIdSorting = d.TransactionID || 0;
				const transactionIdCursor = d.TransactionID || "";
				const refundedAmountSorting = d.RefundedAmount || 0;
				const paidOnSorting = d.PaidOn || 0;
				const operatorFeesSorting = d.OperatorFees || 0;
				const inugoFeeSorting = d.InugoFee || 0;
				const totalAmountSorting = d.TotalAmount || 0;
				const validationDiscountSorting = d.ValidationDiscount || 0;

				return {
					...d,
					_paidStatus,
					_isValidatedSorting,
					_isValidated,
					_parkingChargeSorting,
					_parkingCharge,
					_operatorFees,
					_inugoFee,
					_totalAmount,
					_refundedAmount,
					_siteNames,
					transactionIdCursor,
					transactionIdSorting,
					OrganizationID: organizationId,
					refundedAmountSorting,
					paidOnSorting,
					operatorFeesSorting,
					inugoFeeSorting,
					totalAmountSorting,
					validationDiscountSorting,
				};
			}) || []
		);
	}

	const bulkDownloadReport = useMutateData(gql`
		mutation ($args: BulkDownloadReportInput!) {
			bulkDownloadRevenueReport(args: $args)
		}
	`);

	const skipDataQuery =
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		!paginate.pageSize ||
		!paginate.sortBy ||
		!paginate.sortOrder;

	const {
		data: revenueResult,
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query (
				$organizationId: Int!
				$siteList: [Int!]
				$startDateTime: Timestamp!
				$endDateTime: Timestamp!
				$cursor: String
				$page: PageInfo!
				$bulkDownload: Boolean
			) {
				getRevenueReportForOrganizationV2(
					organizationId: $organizationId
					siteList: $siteList
					startDateTime: $startDateTime
					endDateTime: $endDateTime
					cursor: $cursor
					page: $page
					bulkDownload: $bulkDownload
				) {
					trueLength
					previousEvent
					revenue {
						_transactionId
						BaseAmount
						CreditCardType
						duration
						Email
						FirstName
						InugoFee
						IsPaid
						ItemID
						ItemType
						ItemTypeFormatted
						LastName
						OperatorFees
						PaidOn
						paidOnDate
						TaxAmount
						RankingScore
						RefundedAmount
						RefundRequestID
						RemittedOn
						SiteID
						SiteName
						SiteNames
						TotalAmount
						TransactionID
						TransactionType
						UserLeaseID
						ValidationDiscount
						WasBillable
						WasVoided
						MethodOfPayment
						RemittedOnFormatted
						PaidOnFormatted
						CanRefund
					}
					statistics {
						monthlyCount
						netUserCharges
						refundCount
						totalCount
						totalCreditCardFees
						totalFees
						totalInugoConsumerPaidFees
						totalInugoFees
						totalRefunded
						totalUnpaid
						totalUserCharges
						transientCount
						unpaidCount
					}
				}
			}
		`,
		{
			organizationId,
			siteList: state.options.sites,
			startDateTime: moment(state.options["start-date-time"])
				.startOf("day")
				.format(),
			endDateTime: moment(state.options["end-date-time"]).endOf("day").format(),
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
			bulkDownload: false,
		},
		skipDataQuery
	);

	const data = revenueResult?.getRevenueReportForOrganizationV2;

	const revenueData = formatData(data?.revenue || []);

	const canRefund = usePermissions(null, "RefundUser", true);
	const canResendReceipt = usePermissions(null, "ResendReceipt", true);
	const canManageSessions = usePermissions(null, "SessionAdmin", true);
	const isAdmin = usePermissions("IsAdmin");

	function refetchData() {
		setTimeout(() => {
			refetch();
		}, 300);
	}

	function showTransactionDetails(
		siteId,
		sessionId,
		leaseId,
		productPurchaseId,
		externalParkingSessionId,
		user,
		row
	) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedParkingSessionId: sessionId || null,
			selectedLeaseId: leaseId || null,
			selectedProductPurchaseId: productPurchaseId,
			selectedExternalParkingSessionId: externalParkingSessionId,
			user: user,
			selectedRow: row,
		}));
		refetchData();
	}

	function hideTransactionDetails() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedParkingSessionId: null,
			selectedLeaseId: null,
			selectedProductPurchaseId: null,
			selectedExternalParkingSessionId: null,
			reportStyleOverride: {},
			user: null,
		}));
		refetchData();
	}

	function showRefundDetails(siteId, refundRequestId) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedRefundRequestId: refundRequestId,
			reportStyleOverride: { display: "none" },
		}));
		refetchData();
	}

	function hideRefundDetails() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedRefundRequestId: null,
			reportStyleOverride: {},
		}));
		refetchData();
	}

	function showTransactionWizard(mode, transaction) {
		setState((_state) => ({
			..._state,
			transactionWizardMode: mode,
			transactionWizardTransaction: transaction,
		}));
		refetchData();
	}

	const trueLength = data?.trueLength || 0;
	const stats = data?.statistics || {};

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				style={state.reportStyleOverride}
				title="Transaction History"
				data={revenueData}
				csvName={"inugo-revenue-report.csv"}
				columns={columns({
					permissions: {
						canRefund,
						canResendReceipt,
						isAdmin,
						canManageSessions,
					},
					showTransactionDetails,
					showRefundDetails,
					showTransactionWizard,
				})}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={false}
				getTrProps={(rowInfo) => {
					if (rowInfo && rowInfo.original) {
						if (
							rowInfo.original.TransactionType === "Refund" ||
							!rowInfo.original.IsPaid
						) {
							return { style: { color: `${colours.red}` } };
						} else {
							return { style: { color: "inherit" } };
						}
					}
				}}
				dataTrueLength={trueLength}
				cursorColumn={(x) => `${x.ItemID}-${x.transactionIdCursor}`}
				paginationIsLoading={isLoading || skipDataQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={data?.getRevenueReportForOrganization?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
				onBulkDownloadClicked={async () => {
					try {
						await bulkDownloadReport({
							variables: {
								args: {
									organizationId,
									startDateTime: moment(state.options["start-date-time"])
										.startOf("day")
										.format(),
									endDateTime: moment(state.options["end-date-time"])
										.endOf("day")
										.format(),
									siteIds: state.options.sites,
									version: state.isExperimental ? "v2" : "v1",
									page: {
										sortBy: paginate.sortBy,
										sortOrder: paginate.sortOrder,
										searchTokens: paginate.searchTokens,
									},
								},
							},
						});

						Alert.success("The report will be sent to your email shortly");
					} catch (error) {
						Alert.error("Something went wrong please try again");
					}
				}}
				stats={
					<div>
						<div>
							<FlexWrapper style={{ marginBottom: -16 }}>
								<Card>
									<StatCard
										value={trueLength}
										title="Total Transactions"
										subTitle="Within Selected Dates"
										size="medium"
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.transientCount || 0}
										title="Transient Payments"
										subTitle="Within Selected Dates"
										size="medium"
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.monthlyCount || 0}
										title="Booking Payments"
										subTitle="Within Selected Dates"
										size="medium"
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.refundCount || 0}
										title="Refunds"
										subTitle="Within Selected Dates"
										size="medium"
										color={colours.red}
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.unpaidCount || 0}
										title="Unpaid"
										subTitle="Within Selected Dates"
										size="medium"
										color={colours.red}
									/>
								</Card>
							</FlexWrapper>
						</div>
						<Card>
							<FlexWrapper style={{ marginBottom: -16 }}>
								<StatCard
									value={"+ " + format.money(stats ? stats.totalFees : 0)}
									title="Parking Charges"
									size="mini"
									color={colours.blue}
								/>
								<StatCard
									value={"+ " + format.money(stats.totalCreditCardFees || 0)}
									title="Credit Card Fees"
									size="mini"
									color={colours.blue}
								/>
								<StatCard
									value={
										"+ " + format.money(stats.totalInugoConsumerPaidFees || 0)
									}
									title="Convenience Fees"
									size="mini"
									color={colours.green}
								/>
								<StatCard
									value={"- " + format.money(Math.abs(stats.totalUnpaid || 0))}
									title="Unpaid"
									size="mini"
									color={colours.red}
								/>
								<StatCard
									value={
										"- " + format.money(Math.abs(stats.totalRefunded || 0))
									}
									title="Refunded"
									size="mini"
									color={colours.red}
								/>
								<StatCard
									value={format.money(stats.totalUserCharges || 0)}
									title="Total User Charges"
									size="mini"
									color={colours.darkGrey}
								/>
								<StatCard
									value={
										"- " + format.money(Math.abs(stats.totalInugoFees || 0))
									}
									title="Convenience Fees"
									size="mini"
									color={colours.green}
								/>
								<StatCard
									value={format.money(stats.netUserCharges || 0)}
									title="Net User Charges"
									size="mini"
									color={colours.darkGrey}
								/>
							</FlexWrapper>
						</Card>
					</div>
				}
			/>

			{state.transactionWizardTransaction && (
				<TransactionWizard
					close={() => {
						setState((_state) => ({
							..._state,
							transactionWizardTransaction: null,
						}));
						refetchData();
					}}
					mode={state.transactionWizardMode}
					transaction={state.transactionWizardTransaction}
					selectedOrganization={props.selectedOrganization}
				/>
			)}
			{(state.selectedParkingSessionId ||
				state.selectedLeaseId ||
				state.selectedProductPurchaseId ||
				state.selectedExternalParkingSessionId) && (
				<TransactionDetails
					{...props}
					user={state.user}
					siteId={state.selectedSiteId}
					session={
						state.selectedParkingSessionId
							? { ParkingSessionID: state.selectedParkingSessionId }
							: null
					}
					lease={
						state.selectedLeaseId
							? { UserLeaseID: state.selectedLeaseId }
							: null
					}
					productPurchaseId={state.selectedProductPurchaseId}
					externalParkingSessionId={state.selectedExternalParkingSessionId}
					row={state.selectedRow}
					onClose={hideTransactionDetails}
				/>
			)}
			{state.selectedRefundRequestId && (
				<RefundDetails
					{...props}
					siteId={state.selectedSiteId}
					refundRequestId={state.selectedRefundRequestId}
					onClose={hideRefundDetails}
				/>
			)}
		</div>
	);
}
