import { ACCESS_TYPES, PROMOTION_TYPES, VALIDATION_TYPES } from "./constants";
import { find } from "lodash";
import humanizeDuration from "humanize-duration";
import moment from "moment";
import { validate } from ".";

const DATE_FORMAT_SHORT = "DD MMM YYYY";
const DATE_FORMAT_MEDIUM = "HH:mm on DD MMM YYYY";
const DATE_FORMAT_LONG = "HH:mm:ss on DD MMM YYYY";
const CSV_FORMAT = "YYYY-MM-DD, h:mm:ss";

function padValue(value) {
	return String(value).padStart(2, "0");
}

export function localDate(
	value,
	inputFormat = "X",
	dateOnly = false,
	showSeconds = false,
	addOneMinute = false
) {
	const date = moment(value, inputFormat).add(addOneMinute ? 1 : 0, "minutes");

	if (!date.isValid()) {
		return "";
	}

	if (dateOnly) {
		return date.format(DATE_FORMAT_SHORT);
	}

	return showSeconds
		? date.format(DATE_FORMAT_LONG)
		: date.format(DATE_FORMAT_MEDIUM);
}

export function csvLocalDate(value, inputFormat = "X", addOneMinute = false) {
	const date = moment(value, inputFormat).add(addOneMinute ? 1 : 0, "minutes");

	if (!date.isValid()) {
		return "";
	}

	return date.format(CSV_FORMAT);
}

export function localDateTimestamp(value, inputFormat) {
	return moment(value, inputFormat).unix();
}

/**
 * Humanizes the provided duration (in seconds).
 * @example
 * duration(6789)             // returns "1 hour 53 minutes"
 * duration(6789, true)       // returns "1 hour 53 minutes 9 seconds"
 * duration(6789, true, true) // returns "1h 53m 9s"
 * duration(6789, true, true, true) // returns "01:53:09"
 *
 * @param value
 * @param showSeconds
 * @param useLetters
 * @param useClockFormat
 */
export function duration(
	value,
	showSeconds = false,
	useLetters = false,
	useClockFormat = false
) {
	const options = {
		units: showSeconds ? ["d", "h", "m", "s"] : ["d", "h", "m"],
		delimiter: " ",
		round: true,
	};

	if (useLetters) {
		Object.assign(options, {
			spacer: "",
			language: "shortEn",
			languages: {
				shortEn: { d: () => "d", h: () => "h", m: () => "m", s: () => "s" },
			},
		});
	}

	if (!useClockFormat) return humanizeDuration(value * 1000, options);

	const time = moment(value, "X");
	const hours = time.diff(moment(0), "hours");
	const minutes = time.diff(moment(0), "minutes") - hours * 60;
	const seconds = time.diff(moment(0), "seconds") - hours * 3600 - minutes * 60;

	const secondsString = showSeconds ? `:${padValue(seconds)}` : "";
	return `${padValue(hours)}:${padValue(minutes)}${secondsString}`;
}

export function money(
	value,
	showFree = false,
	includeDollarSign = true,
	isNegativeAccountingValue = false,
	transactionType
) {
	const _value = parseFloat(value);

	if (!isNaN(_value)) {
		if (showFree && _value === 0) return "Free";
		const isRefund = transactionType === "Refund";

		const formattedValue =
			_value >= 0
				? `${includeDollarSign ? "$" : ""}${_value.toFixed(2)}`
				: `${includeDollarSign ? "- $" : ""}${(0 - _value).toFixed(2)}`;

		const accountingValue =
			(isNegativeAccountingValue || isRefund) && _value !== 0
				? `(${formattedValue})`
				: formattedValue;

		// insert commas to break up longer values
		return accountingValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return showFree ? "Free" : `${includeDollarSign ? "$" : ""}0.00`;
}

export function percentage(value, showDecimals = false) {
	if (!isNaN(parseFloat(value))) {
		const formattedValue = showDecimals
			? `${parseFloat(value).toFixed(2)} %`
			: `${parseFloat(value).toFixed(0)} %`;

		// insert commas to break up longer values
		return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return showDecimals ? "0.00 %" : "0 %";
}

/**
 * Humanizes a range with a start and end date.
 * @param {string[]} range
 * @returns {string} DD MMM YYYY to DD MMM YYYY
 */
export function dateRange(range) {
	const start = moment(range[0]).format("DD MMM YYYY");
	const end = moment(range[1]).format("DD MMM YYYY");
	return `${start} to ${end}`;
}

export function plate(text) {
	text = text && text.replace(/\W/g, "");
	return validate.plate(text) ? text.toUpperCase() : undefined;
}

export function validation(value = 0, type, rates, sites) {
	switch (type) {
		case VALIDATION_TYPES.FIXED:
			return `-$${value.toFixed(2)}`;
		case VALIDATION_TYPES.PERCENTAGE:
			return `-${value.toFixed(2)}%`;
		case VALIDATION_TYPES.DURATIONDAYS:
		case VALIDATION_TYPES.DURATIONHOURS:
			return type === VALIDATION_TYPES.DURATIONDAYS
				? `${value} days free`
				: `${value} hours free`;
		case VALIDATION_TYPES.RATE:
			return rates
				? rates
						.map((rate) => {
							const _site = find(sites, (site) => site.SiteID === rate.SiteID);
							return _site
								? `${_site.Name}: ${rate.RateID} (${rate.Name})`
								: `${rate.RateID} (${rate.Name})`;
						})
						.join(", ")
				: "Unknown Rate";
		default:
			return "Unknown";
	}
}

export function promotion(value = 0, type) {
	switch (type) {
		case PROMOTION_TYPES.FIXED:
			return `($${value.toFixed(2)})`;
		case PROMOTION_TYPES.PERCENTAGE:
			return `-${value.toFixed(2)}%`;
		case PROMOTION_TYPES.DURATIONDAYS:
			return `-${value.toFixed(2)} days`;
		case PROMOTION_TYPES.DURATIONHOURS:
			return `-${value.toFixed(2)} hours`;
		default:
			return "";
	}
}

export function formatFee(data = 0, validationType) {
	switch (validationType) {
		case VALIDATION_TYPES.FIXED:
			return `$${(data.fee ?? 0).toFixed(2)}`;
		case VALIDATION_TYPES.DURATIONDAYS:
			return `${(data.fee ?? 0).toFixed(2)} days`;
		case VALIDATION_TYPES.DURATIONHOURS:
			return `${(data.fee ?? 0).toFixed(2)} hours`;
		default:
			return `${(data.fee ?? 0).toFixed(2)}%`;
	}
}

export function formatLane(lane, node) {
	const laneType = (node && ACCESS_TYPES[node.AccessType]) || "";

	if (!lane || (!lane.Name && !lane.GroupName && !lane.GroupOrder)) {
		return laneType;
	}

	const laneGroup = `${lane.GroupName || ""} ${lane.GroupOrder || ""}`.trim();

	return lane.Name
		? `${lane.Name} (${laneType})`
		: `${laneGroup || "No Name"} (${laneType})`;
}

export function formatGate(node) {
	if (!node) {
		return "";
	}

	return node.Name
		? `${node.Name} (${node.SerialNumber})`
		: node.SerialNumber || "";
}

export function addCommasToNumber(num, alt = 0) {
	if (!num) {
		return alt;
	}
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function parseGqlError(error) {
	if (error.graphQLErrors && error.graphQLErrors[0]) {
		return error.graphQLErrors[0].message.replace(/^GQL: /g, "");
	}

	return "";
}

export function dailyFeeRange(dailyFeesObject) {
	const dailyFees = Object.values(dailyFeesObject);
	const minFee = Math.min(...dailyFees);
	const maxFee = Math.max(...dailyFees);
	if (minFee === maxFee) {
		return money(minFee);
	}

	return `${money(minFee)} - ${money(maxFee)}`;
}

export function formatDiscountTypeDisplay(discountType) {
	switch (discountType) {
		case "Fixed":
			return "Fixed Amount";
		case "Percentage":
			return "Percentage";
		case "DurationDays":
			return "Duration (Days)";
		case "DurationHours":
			return "Duration (Hours)";
		case "Rate":
			return "Rate";
		default:
			return "Unknown";
	}
}
