import * as Yup from "yup";
import * as api from "./api";
import WizardLayout, { StepText } from "../WizardLayout";
import Button from "../../layout/Button";
import EditableInputField from "../../layout/EditableInputField";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";
import { user } from "../../../helpers";

const maximumRefund = (item) => {
	return parseFloat((item.TotalAmount - (item.RefundedAmount || 0)).toFixed(2));
};

export const refundPaymentStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => {
	const userName = user.fullNameWithEmail(values.transaction);

	return {
		id: "refund",
		label: "Refund",
		render: () => (
			<div>
				<StepText style={{ marginBottom: 48 }}>
					{`Refund the ${values.transaction.ItemType.match(/[A-Z][a-z]+/g)
						.join(" ")
						.toLowerCase()
						.trim()}${userName ? ` for ${userName}` : ""}?`}
				</StepText>
				<EditableInputField
					style={{ width: "50%" }}
					type="number"
					step="0.01"
					min="0.01"
					max={maximumRefund(values.transaction)}
					name="amount"
					useLabel="Amount"
					value={values.amount || values.amount === 0 ? values.amount : ""}
					error={
						values.amount !== null &&
						(values.amount <= 0 ||
							values.amount > maximumRefund(values.transaction))
							? `Please supply an amount above $0 and no more than $${maximumRefund(
									values.transaction
							  ).toFixed(2)}`
							: null
					}
				/>
				<EditableInputField
					style={{ width: "50%" }}
					type="text"
					name="reason"
					useLabel="Reason"
					value={values.reason || ""}
					error={
						values.reason !== null && values.reason.length === 0
							? "Please supply a reason."
							: null
					}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="red"
						onClick={handleSubmit}
						disabled={
							isSubmitting ||
							values.amount <= 0 ||
							values.amount > maximumRefund(values.transaction) ||
							!values.reason
						}
						keyStrokeHandler={keyStrokeHandler}
					>
						Refund Payment
					</Button>,
				]}
			/>
		),
	};
};

export const updateUserStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "update-user",
	label: "Update User",
	render: () => (
		<div>
			<StepText style={{ marginBottom: 48 }}>
				Please enter the users details for this{" "}
				{values.transaction.ItemType.match(/[A-Z][a-z]+/g)
					.join(" ")
					.toLowerCase()
					.trim()}{" "}
			</StepText>
			<EditableInputField
				style={{ width: "50%" }}
				type="text"
				name="email"
				useLabel="Email"
				value={values?.email || ""}
				error={
					values?.email !== null && values?.email?.length === 0
						? "Please supply a valid email address."
						: null
				}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || values?.email === null}
					keyStrokeHandler={keyStrokeHandler}
				>
					Update User
				</Button>,
			]}
		/>
	),
});

export const resendReceiptStep =
	(verb = "Resend") =>
	({ handleSubmit, isSubmitting, values, wizardProps, keyStrokeHandler }) => ({
		id: "resend",
		label: "Resend",
		render: () => (
			<div>
				<StepText>
					{verb} the receipt for this{" "}
					{values.transaction.ItemType.match(/[A-Z][a-z]+/g)
						.join(" ")
						.toLowerCase()
						.trim()}{" "}
					to {user.fullNameWithEmail(values.transaction)}?
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="red"
						onClick={handleSubmit}
						disabled={isSubmitting}
						keyStrokeHandler={keyStrokeHandler}
					>
						{verb} Receipt
					</Button>,
				]}
			/>
		),
	});

export const voidPaymentStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "void",
	label: "Void Payment",
	render: () => (
		<div>
			<StepText>
				Mark{" "}
				{values.transaction.ItemType.match(/[A-Z][a-z]+/g)
					.join(" ")
					.toLowerCase()
					.trim()}{" "}
				for {user.fullNameWithEmail(values.transaction)} as void? The user will{" "}
				no longer be held accountable to pay for it.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Confirm
				</Button>,
			]}
		/>
	),
});

export const retryPaymentStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "retry",
	label: "Retry",
	render: () => (
		<div>
			<StepText>
				Retry the payment for this{" "}
				{values.transaction.ItemType.match(/[A-Z][a-z]+/g)
					.join(" ")
					.toLowerCase()
					.trim()}{" "}
				for {user.fullNameWithEmail(values.transaction)}?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Retry Payment
				</Button>,
			]}
		/>
	),
});

export default function TransactionWizard(props) {
	let steps = [];

	if (props.mode === "refund-payment") {
		steps = [refundPaymentStep];
	} else if (props.mode === "resend-receipt") {
		steps = [resendReceiptStep()];
	} else if (props.mode === "retry-payment") {
		steps = [retryPaymentStep];
	} else if (props.mode === "void-payment") {
		steps = [voidPaymentStep];
	} else if (props.mode === "update-user") {
		steps = [updateUserStep];
	} else if (props.mode === "send-receipt") {
		steps = [resendReceiptStep("Send")];
	}

	const refundProductPurchase = useMutateData(gql`
		mutation ($productPurchaseId: Int!, $amount: Float!, $reason: String) {
			refundProductPurchase(
				productPurchaseId: $productPurchaseId
				amount: $amount
				reason: $reason
			)
		}
	`);

	const resendProductPurchaseRefundReceipt = useMutateData(gql`
		mutation ($refundRequestId: Int!) {
			resendProductPurchaseRefundReceipt(refundRequestId: $refundRequestId)
		}
	`);

	const resendProductPurchaseReceipt = useMutateData(gql`
		mutation ($productPurchaseId: Int!) {
			resendProductPurchaseReceipt(productPurchaseId: $productPurchaseId)
		}
	`);

	const updateExternalParkingSessionUser = useMutateData(gql`
		mutation ($externalId: Int!, $email: Email!) {
			updateExternalParkingSessionUser(externalId: $externalId, email: $email)
		}
	`);

	const sendExternalParkingSessionReceipt = useMutateData(gql`
		mutation ($externalId: Int!) {
			sendExternalParkingSessionReceipt(externalId: $externalId)
		}
	`);

	const refundExternalParkingSession = useMutateData(gql`
		mutation (
			$externalParkingSessionId: Int!
			$amount: Float!
			$reason: String
		) {
			refundExternalParkingSession(
				externalParkingSessionId: $externalParkingSessionId
				amount: $amount
				reason: $reason
			)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				if (values.mode === "refund-payment") return "Refund payment";
				if (values.mode === "resend-receipt") return "Resend receipt";
				if (values.mode === "retry-payment") return "Retry payment";
				if (values.mode === "void-payment") return "Mark Payment as Void";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "transaction",
					value: props.transaction,
					validator: Yup.object(),
				},
				{
					name: "amount",
					value: parseFloat(
						props.transaction.TotalAmount - props.transaction.RefundedAmount
					).toFixed(2),
					validator: Yup.number().nullable(),
				},
				{
					name: "reason",
					value: null,
					validator: Yup.string().nullable(),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);

				if (values.mode === "refund-payment") {
					api.submitRefundPayment(props, values, setSubmitting, {
						refundProductPurchase,
						refundExternalParkingSession,
					});
				} else if (values.mode === "resend-receipt") {
					api.submitResendReceipt(props, values, setSubmitting, {
						resendProductPurchaseRefundReceipt,
						resendProductPurchaseReceipt,
					});
				} else if (values.mode === "retry-payment") {
					api.submitRetryPayment(props, values, setSubmitting);
				} else if (values.mode === "void-payment") {
					api.submitVoidPayment(props, values, setSubmitting);
				} else if (values.mode === "update-user") {
					api.updateExternalParkingSessionUser(props, values, setSubmitting, {
						updateExternalParkingSessionUser,
					});
				} else if (values.mode === "send-receipt") {
					api.sendExternalParkingSessionReceipt(props, values, setSubmitting, {
						sendExternalParkingSessionReceipt,
					});
				}
			}}
			steps={steps}
			wizardProps={props}
		/>
	);
}
